import { Col, Row } from "react-bootstrap";

const Handover = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>
            <b>
              If customer has Elastic contract, we need to work with the
              Champion to validate:{" "}
            </b>
          </div>
          <ul>
            <li>They can consume against this existing contract</li>
            <li>
              If anybody else needs to approve consumption against that contract
            </li>
            <li>If a cost justification analysis is required </li>
          </ul>

          <div>
            <b>If customer does not have contract in place:</b>
          </div>
          <ul>
            <li>Establish the preferred commercial route</li>
            <li>Prioritize the path with the least resistance:</li>

            <ul>
              <li>Marketplace Pay as you Go</li>
              <li>Marketplace Private offer</li>
              <li>Direct $0 contract + CSA</li>
              <li>Negotiated CSA</li>
            </ul>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Handover;
