import { Col, Row } from "react-bootstrap";

const NBMGate = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>Goal of NBM</div>
          <ul>
            <li>Qualify Champion</li>
            <li>Validate “decision is made”</li>
            <li>Validate importance of NWL</li>
            <li>Validate urgency to start</li>
            <li>Sell pre-onboarding as a way to de-risk and speed-up</li>
          </ul>
          <div>
            <b>Use CoM Summary</b> to show we understand their NWL
          </div>

          <br />

          <div>
            <b>
              Use Relevant proof Points and/or outline relevant risks of
              skipping pre-onboarding
            </b>{" "}
            (SA to support) to sell the value
          </div>
          <br />

          <div>How to Close NBM</div>
          <ul>
            <li>Agree on pre-onboarding date(s) and participants</li>
          </ul>

          <div>Attendance</div>
          <ul>
            <li>SA</li>
            <li>Rep</li>
            <li>RD (optional)</li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1dlAHJ62MZbEb1dDXPUd5WG3EowBAAa0xr-nNC2hiaZc/edit#slide=id.g20e67d3d019_0_10594">
                NBM Template: Tiger Fast
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1h-xYXoYvKcUdMK8LTJ9PZNnOfzgSMqvrKYJ6xiGGpCU/edit#slide=id.g8a5b4d2676_0_0">
                Value Pyramids Training (AE)
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default NBMGate;
