import { Col, Row } from "react-bootstrap";

const Echoback = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <br />
          <div>
            MongoDB Sprint Zero <b>debrief</b> and <b>next steps</b>
          </div>
          <br />
          <div>
            <b>MongoDB SA</b> to showcase the echo back to the decision maker
          </div>
          <br />
          <div>
            <b>Customer stakeholders should include :</b>
          </div>
          <ul>
            <li>The product owner</li>
            <li>The dev team lead</li>
          </ul>

          <div>Optionally other non-participating dev team members</div>
          <br />
          <div>
            <b>Showcase to Champion</b> what they have built & validate all
            initial objections have been addressed
          </div>
          <br />

          <div>
            <b>Show achievements</b> and summarise main learnings and takeaways
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Echoback;
