export const navCardData = [
  {
    title: "AI MeetingPrep - SFDC",
    text: "Plan your next meeting. Summarize notes from past meetings per opportunity and see a summary.",
    img: "homeTiles/pexels-tara-winstead-8386440.jpg",
    salesSegment: ["all"],
    cardLink: "ai-sfdc",
    // leadTime: "N/A",
    href: "ai-sfdc",
    filter: ["all", "ai-help"],
  },

  {
    title: "AI MeetingPrep - Notes Analyzer",
    text: "Plan your next meeting. Summarize notes from your own documents.",
    img: "homeTiles/pexels-fauxels-3183170.jpg",
    // salesSegment: ["all"],
    // cardLink: "notes-sum",
    // leadTime: "N/A",
    href: "ai-notes",
    filter: ["all", "ai-help"],
  },

  {
    title: "Ask CoachGTM",
    text: "Got a question for the vast MongoDB knowledgebase?  Ask CoachGTM in Slack.",
    img: "coachgtmbot.png",
    salesSegment: ["all"],
    cardLink: "coachgtm",
    // leadTime: "N/A",
    href: "https://mongodb.enterprise.slack.com/archives/C060VBD2URK",
    filter: ["all", "ai-help"],
  },

  {
    title: "EARR Calculator",
    text: "Update your NWL Opportunity with the estimate (ERR) while you are still in the Discovery Stage.  Not to be shared with customer.  For directional sizing only.  Discuss with SA before submission.",
    img: "homeTiles/pexels-eye4dtail-374918.jpg",
    salesSegment: ["all"],
    cardLink: "earrcalculator",
    // leadTime: "",
    href: "earrcalculator",
    filter: ["all", "processes"],
  },

  {
    title: "SA HVAs (High Value Assets)",
    text: "See a list of high value engagements SAs participate in.",
    img: "earr.jpg",
    salesSegment: ["growth", "rsc", "all"],
    cardLink: "hva",
    // leadTime: "",
    href: "https://docs.google.com/document/d/1tGI7DlNVpD9AYfJhn7UTdhrQl01rTkoHj-DrEt8fh5Q/edit",
    filter: ["all", "processes"],
  },

  {
    title: "RSC Intake Form",
    text: "RSC supports early stage activities, full end-to-end offerings, and even post sales discussions with CSM/Marketing. Use for Discovery, Webinars, Workshops, etc.",
    img: "disco.jpg",
    salesSegment: ["all"],
    cardLink: "rsc",
    // leadTime: "2 Days",
    href: "https://censos.mside.app",
    filter: ["all", "forms"],
  },

  {
    title: "Growth SAs Intake Form",
    text: "Please be prepared to provide Acct and Opp as well as 3 Whys.  A prep meeting will be required.",
    img: "homeTiles/pexels-olly-3758105.jpg",
    salesSegment: ["growth", "all"],
    leadTime: "2 Days",
    cardLink: "request-sa",
    href: "request-sa",
    // cardLink: "form/sa",
    // href: "form/sa",
    filter: ["all", "forms"],
  },

  {
    title: "Specialist Intake Form",
    text: "The more information that is provided to the specialist team the quicker the engagement will complete.",
    img: "homeTiles/pexels-kevin-ku-92347-577585.jpg",
    salesSegment: ["specialist", "all"],
    cardLink: "specialist",
    leadTime: "2 Days",
    href: "https://specialists.sa.prod.corp.mongodb.com/Help",
    filter: ["all", "forms"],
  },

  {
    title: "BVC Intake Form and Info",
    text: "Customer facing consultants who business value clarity, connecting the dots between customer business requirements, technology, and value.",
    img: "bvc.jpg",
    salesSegment: ["bvc", "all"],
    cardLink: "bvc",
    // leadTime: "N/A",
    href: "https://mongodb.highspot.com/spots/5ae887bf3e255f72e6f4b0d2",
    filter: ["all", "processes", "forms"],
  },

  {
    title: "DevRel",
    text: "Contact DevRel for a Design Review session with an expert. (Click Home on request form for more info)",
    img: "homeTiles/pexels-realtoughcandy-11035481.jpg",
    salesSegment: ["growth", "specialist", "all"],
    cardLink: "devrel",
    leadTime: "5 days",
    href: "https://sites.google.com/mongodb.com/fy24-strategic-devrel-programs/request-support",
    filter: ["all", "forms"],
  },

  {
    title: "Request a Security Expert",
    text: "The page behind the form has more information about what you can request from Cybersecurity Governance, Risk, & Compliance (GRC) ",
    img: "homeTiles/pexels-pixabay-60504.jpg",
    salesSegment: ["growth", "specialist", "all"],
    cardLink: "security",
    // leadTime: "N/A",
    href: "https://jira.mongodb.org/plugins/servlet/desk/portal/30/create/593",
    filter: ["all", "forms"],
  },

  {
    title: "PS Project Status",
    text: "See the current Project Status of PS Consultants",
    img: "ps.jpg",
    salesSegment: ["ps", "all"],
    cardLink: "ps",
    // leadTime: "N/A",
    href: "https://tableau.corp.mongodb.com/#/views/PSprojectstatus/FieldDelivery?:iid=1",
    filter: ["all", "processes", "other"],
  },

  {
    title: "WL Accelerators PG Kit",
    text: "Once in production, help customers manage the performance, availability, and scalability of their deployment.",
    img: "homeTiles/pexels-pixabay-2159.jpg",
    salesSegment: ["all"],
    cardLink: "wl-accelerators",
    // leadTime: "N/A",
    href: "https://mongodb.highspot.com/items/65aad9bf897928ae9eb462fc?lfrm=srp.2#1",
    filter: ["all", "processes", "other"],
  },

  {
    title: "Tiger Route Assets and Instructions",
    text: "Need the latest docs or instructions for your engagement?  Find them here.",
    img: "homeTiles/pexels-sayantan-kundu-345608-951007.jpg",
    salesSegment: ["all"],
    cardLink: "tiger-route-assets",
    // leadTime: "N/A",
    href: "https://docs.google.com/presentation/d/1OTck3Nv6ZsuaMHUdRQq_3lkBX7QbiJ4zAhRAIqy1GQw/edit#slide=id.g1fe02acc8d3_3_2",
    filter: ["all", "processes", "other"],
  },

  {
    title: "MAAP",
    text: "MongoDB AI Applications Program. Click here to validate if MAAP is a good fit, engage with a partner, share feedback on materials, or ask a question to our ecosystem.",
    img: "homeTiles/pexels-pavel-danilyuk-8438879.jpg",
    salesSegment: ["all", "ai", "ps", "specialist"],
    cardLink: "maap-specialist",
    // leadTime: "N/A",
    href: "https://censos.mside.app/specialistForm/maap-specialist",
    filter: ["all", "processes", "other", "forms"],
  },

  // {
  //   title: "Request EM on Slack",
  //   text: "Request an EM for your meeting right on their slack channel. #americas-services-sales",
  //   img: "disco.jpg",
  //   salesSegment: ["all"],
  //   cardLink: "em",
  //   leadTime: "N/A",
  //   href: "https://mongodb.enterprise.slack.com/archives/C04NZEYH7UM",
  // },
];
