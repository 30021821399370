export const emailToOptions = [
  {
    name: "Sahil Kedar",
    email: "sahil.kedar@mongodb.com",
    region: "SouthEast",
  },
  { name: "Derek Parker", email: "derek.parker@mongodb.com", region: "" },
  { name: "Blake Schoppa", email: "blake.schoppa@mongodb.com", region: "" },
  { name: "Marianne Myers", email: "marianne.myers@mongodb.com", region: "" },
  {
    name: "Mayur Nagarsheth",
    email: "mayur.nagarsheth@mongodb.com",
    region: "",
  },
  { name: "Andrew Smith", email: "andrew.smith@mongodb.com", region: "" },
  { name: "Ali Hussain", email: "ali.hussain@mongodb.com", region: "" },
  { name: "Roy Kiesler", email: "roy.kiesler@mongodb.com", region: "" },
  { name: "Charles Lee", email: "charles.lee@mongodb.com", region: "" },
  { name: "Blaine Mincey", email: "blaine.mincey@mongodb.com", region: "" },
  {
    name: "Sunsharay Chestnut",
    email: "sunsharay.chestnut@mongodb.com",
    region: "",
  },
  {
    name: "Zuzana Matevossian",
    email: "zuzana.matevossian@mongodb.com",
    region: "",
  },
  { name: "Diego Bursztyn", email: "diego.bursztyn@mongodb.com", region: "" },
  { name: "Monica Morgan", email: "monica.morgan@mongodb.com", region: "" },
  { name: "Jithin George", email: "jithin.george@mongodb.com", region: "" },
  //
  {
    name: "Rosston Ritter",
    email: "rosston.ritter@mongodb.com",
    region: "SE SA",
  },
];
