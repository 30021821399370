import { Col, Row } from "react-bootstrap";

const Discovery = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>SA Verifies Command of the Message (CoM) Fundamentals</div>
          <ul>
            <li>Before Scenario</li>
            <li>Negative Consequences</li>
            <li>After Scenario</li>
            <li>PBO (optional)</li>
            <li>RCs (Why MongoDB)</li>
            <li>Value Driver(s)</li>
          </ul>

          <div>Understand the NWL</div>
          <ul>
            <li>Confirm Single NWL</li>
            <li>
              <a href="https://saservices-wtaii.mongodbstitch.com/earrcalculator">
                High Level Sizing
              </a>
            </li>
            <li>Use Case</li>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1c28e9e877f_2_5022">
                Sales Motion
              </a>
            </li>
          </ul>

          <div>Champion</div>
          <ul>
            <li>Identify potential Champion</li>
            <li>Triangulate w/multiple people/partners</li>
          </ul>

          <div>Urgency</div>
          <ul>
            <li>
              Uncover project timelines and impact of missing dates or benefit
              of getting there faster
            </li>
          </ul>

          <div>Competitiveness</div>
          <ul>
            <li>Understand why decision is made for MongoDB</li>
            <li>MongoDB experience</li>
          </ul>
          <div>Decision Process</div>
          <ul>
            <li>Did the Champion make the decision?</li>
            <li>Any TCO/other steps required?</li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
          <div>AE/SA/CSM/EM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/document/d/1WVytzc-LIqRrxJzkofktj23GtmewqteFcuR_FPDqav0/edit">
                Discovery Capture Sheet
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/5d08128ec79c52759860cac4#1">
                Tiger Sales Process Playbook
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/65241a9d650fbb2f9b7d05ac">
                Value Cards
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/5ef6049ea2e3a944f44333fd#1">
                CoM Focused Discovery Questions
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8">
                Pre-onboarding & MS0
              </a>
            </li>
          </ul>
          <div>SA</div>
          <ul>
            <li>
              <a href="https://docs.google.com/spreadsheets/d/1Mn_lRdSKhN2fWc6ZQ70rN1ehKP3rCLCN7fjTrRc8tp4/edit#gid=249721064">
                Example Discovery Questions
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/5d08128ec79c52759860cac4#1">
                Tiger Sales Process Playbook
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8">
                SA Tiger Training
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8">
                Pre-onboarding & MS0
              </a>
            </li>
          </ul>

          <div>CSM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
          </ul>

          <div>EM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Discovery;
