export const fastStages = {
  title: "Fast Discovery",
  keyActivities: [
    {
      topic: "SA Verifies Command of the Message (CoM) Fundamentals",
      items: [
        "Before Scenario",
        "Negative Consequences",
        "After Scenario",
        "PBO (optional)",
        "RCs (Why MongoDB)",
        "Value Driver(s)",
      ],
    },
    {
      topic: "Understand the NWL",
      items: [
        "Confirm Single NWL",
        "High Level Sizing",
        "Use Case",
        "Sales Motion",
      ],
    },
    {
      topic: "Champion",
      items: [
        "Identify potential Champion",
        "Triangulate w/multiple people/partners",
      ],
    },
    {
      topic: "Urgency",
      items: [
        "Uncover project timelines and impact of missing dates or benefit of getting there faster",
      ],
    },
    {
      topic: "Competitiveness",
      items: [
        "Understand why decision is made for MongoDB",
        "MongoDB experience",
      ],
    },
    {
      topic: "Decision Process",
      items: [
        "Did the Champion make the decision?",
        "Any TCO/other steps required?",
      ],
    },
  ],
  assetList: [
    {
      role: "AE",
      assets: [
        {
          title: "Discovery Capture Sheet",
          link: "https://docs.google.com/document/d/1WVytzc-LIqRrxJzkofktj23GtmewqteFcuR_FPDqav0/edit",
        },
      ],
    },
    {
      role: "SA",
      assets: [
        {
          title: "Discovery Capture Sheet",
          link: "https://docs.google.com/document/d/1WVytzc-LIqRrxJzkofktj23GtmewqteFcuR_FPDqav0/edit",
        },
      ],
    },
    {
      role: "CSM",
      assets: [
        {
          title: "Discovery Capture Sheet",
          link: "https://docs.google.com/document/d/1WVytzc-LIqRrxJzkofktj23GtmewqteFcuR_FPDqav0/edit",
        },
      ],
    },
    {
      role: "EM",
      assets: [
        {
          title: "Discovery Capture Sheet",
          link: "https://docs.google.com/document/d/1WVytzc-LIqRrxJzkofktj23GtmewqteFcuR_FPDqav0/edit",
        },
      ],
    },
  ],
  otherResources: [
    {
      role: "AE",
      assets: [
        {
          title: "FY24 New Workloads - Growth Enablement",
          link: "https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0",
        },
        {
          title: "Tiger Sales Process Playbook",
          link: "https://mongodb.highspot.com/items/5d08128ec79c52759860cac4#1",
        },
        {
          title: "Value Cards",
          link: "https://mongodb.highspot.com/items/65241a9d650fbb2f9b7d05ac",
        },
        {
          title: "CoM Focused Discovery Questions",
          link: "https://mongodb.highspot.com/items/5ef6049ea2e3a944f44333fd#1",
        },
        {
          title: "Pre-onboarding & MS0",
          link: "https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8",
        },
      ],
    },
    {
      role: "SA",
      assets: [
        {
          title: "Example Discovery Questions",
          link: "https://docs.google.com/spreadsheets/d/1Mn_lRdSKhN2fWc6ZQ70rN1ehKP3rCLCN7fjTrRc8tp4/edit#gid=249721064",
        },
        {
          title: "FY24 New Workloads - Growth Enablement",
          link: "https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0",
        },
        {
          title: "Tiger Sales Process Playbook",
          link: "https://mongodb.highspot.com/items/5d08128ec79c52759860cac4#1",
        },
        {
          title: "SA Tiger Training",
          link: "https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8",
        },
        {
          title: "Pre-onboarding & MS0",
          link: "https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8",
        },
      ],
    },
    {
      role: "CSM",
      assets: [
        {
          title: "FY24 New Workloads - Growth Enablement",
          link: "https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0",
        },
      ],
    },
    {
      role: "EM",
      assets: [
        {
          title: "FY24 New Workloads - Growth Enablement",
          link: "https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0",
        },
      ],
    },
  ],
};
