import { useState } from "react";

// components - LeafyGreen
// import { H1, H2, H3, Body } from "@leafygreen-ui/typography";
import { H2 } from "@leafygreen-ui/typography";
import {
  SegmentedControl,
  SegmentedControlOption,
} from "@leafygreen-ui/segmented-control";
import Card from "@leafygreen-ui/card";
// components - custom/internal - Stages
import Discovery from "./Stages/Discovery";
import NBMGate from "./Stages/NBMGate";
import Scope from "./Stages/Scope";
import GoNoGo from "./Stages/GoNoGo";
import Validation from "./Stages/Validation";
import Echoback from "./Stages/EchobackGate";
import PreOnboarding from "./Stages/PreOnboarding";
import PreOnboardingGate from "./Stages/PreOnboardingGate";
import Negotiate from "./Stages/Negotiate";
import Handover from "./Stages/Handover";
// styles
import "./FastProcess.css";

export const FastProcessComponent = () => {
  const [testStage, setTestStage] = useState("discovery");

  return (
    <Card className="my-card">
      <div>
        <H2>Fast</H2>
      </div>
      {/* <Pipeline size="xsmall" className="my-pipeline">
          <Stage className="stage">Discovery</Stage>
          <Stage>NBM Gate</Stage>
          <Stage>Scope</Stage>
          <Stage>Go/No-Go Gate</Stage>
          <Stage>Validation (BVA/POV/TDD/TFW)</Stage>
          <Stage>Echoback Gate</Stage>
          <Stage>Pre-Onboarding</Stage>
          <Stage>Pre-Onboarding Checklist Complete Gate</Stage>
          <Stage>Negotiate</Stage>
          <Stage>Handover</Stage>
        </Pipeline> */}
      <SegmentedControl
        name="segmented-stages"
        size={"default"}
        darkMode={false}
        followFocus={true}
        defaultValue={"discovery"}
        onChange={(value) => {
          console.log(value);
          setTestStage(value);
        }}
      >
        <SegmentedControlOption value="discovery">
          Discovery
        </SegmentedControlOption>

        <SegmentedControlOption value="nbm gate">
          NBM Gate
        </SegmentedControlOption>

        <SegmentedControlOption value="scope" disabled>
          Scope
        </SegmentedControlOption>

        <SegmentedControlOption value="gonogo" disabled>
          Go/No-Go Gate
        </SegmentedControlOption>

        <SegmentedControlOption value="validation" disabled>
          Validation (BVA/POV/TDD/TFW)
        </SegmentedControlOption>

        <SegmentedControlOption value="echoback" disabled>
          Echoback Gate
        </SegmentedControlOption>

        <SegmentedControlOption value="preonboarding">
          Pre-Onboarding
        </SegmentedControlOption>

        <SegmentedControlOption value="pre checklist">
          Pre-Onboarding Checklist Complete Gate
        </SegmentedControlOption>

        <SegmentedControlOption value="negotiate">
          Negotiate
        </SegmentedControlOption>

        <SegmentedControlOption value="handover">
          Handover
        </SegmentedControlOption>

        {/* <SegmentedControlOption value="dragonfruit" disabled>
          Dragonfruit
        </SegmentedControlOption> */}
      </SegmentedControl>
      {testStage === "discovery" && <Discovery />}
      {testStage === "nbm gate" && <NBMGate />}
      {testStage === "scope" && <Scope />}
      {testStage === "gonogo" && <GoNoGo />}
      {testStage === "validation" && <Validation />}
      {testStage === "echoback" && <Echoback />}
      {testStage === "preonboarding" && <PreOnboarding />}
      {testStage === "pre checklist" && <PreOnboardingGate />}
      {testStage === "negotiate" && <Negotiate />}
      {testStage === "handover" && <Handover />}
    </Card>
  );
};
