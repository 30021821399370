import { useEffect, useState } from "react";
import * as Realm from "realm-web";
import { useOktaAuth } from "@okta/okta-react";
import { app } from "../../realmApp/realmApp";
// import { useNavigate } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
// Components - Leafy
import { H1, Body, Link } from "@leafygreen-ui/typography";
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
import Button from "@leafygreen-ui/button";
// import TextArea from "@leafygreen-ui/text-area";
// import Card from "@leafygreen-ui/card";
import Toast from "@leafygreen-ui/toast";
import Callout from "@leafygreen-ui/callout";
// Components - custom/internal - AI SFDC
import SummaryCard from "./components/SummaryCard";
// Components - custom/internal - General
import TigerLoader from "../../components/Loading/TigerLoader";
// Styles
import "./index.css";

interface Props {
  isProtected: boolean;
}

export const MeetingPrep = ({ isProtected }: Props) => {
  // const navigate = useNavigate();
  const [initialSummary, setInitialSummary] = useState("");
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [finalSummary, setFinalSummary] = useState("");
  // Account state
  const [accountInfo, setAccountInfo] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [searchTermAccountName, setSearchTermAccountName] = useState("");

  // Opp state
  const [selectedOpp, setSelectedOpp] = useState("");
  const [oppsList, setOppsList] = useState([]);
  const [disableOpp, setDisableOpp] = useState(true);
  const [opportunityFieldLabel, setOpportunityFieldLabel] = useState<string>(
    "Salesforce Opportunity Name *"
  );

  // Progress state
  const [progressToastOpen, setProgressToastOpen] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [warningToastOpen, setWarningToastOpen] = useState(false);

  // Loading state
  const [loading, setLoading] = useState(false);

  // Get the user from the context
  const [user, setUser] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  // This is the user used to invoke the functions
  const [appUser, setAppUser] = useState(null);

  useEffect(() => {
    document.title = "STEM: MeetingPrep";
  }, []);

  // App Services/Realm functions
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUser(info);
        loginApiKey();
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const loginApiKey = () => {
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);
    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
    });
  };

  const searchAccount = async (event: any) => {
    if (isAccountSelected) {
      // Reset the flag once we've ignored one change.
      setIsAccountSelected(false);
      return;
    }
    const searchQuery = event.target.value;
    if (user === undefined) return;
    setAccountInfo(searchQuery);
    if (searchQuery.length < 3) {
      setAccountList([]);
      return;
    }
    let search = { searchTerm: searchQuery };
    const response = await appUser.functions.search_account(search);
    // console.log("searchAccount response", response);
    setAccountList(response.result);
    // setAccountList(["hello", "world", "this", "is", "a", "test"]);
    //clean the opp field
    setSelectedOpp("");
  };

  useEffect(() => {
    searchAccount(searchTermAccountName);
  }, [searchTermAccountName]);

  const selectAccount = async (acct: any) => {
    setIsAccountSelected(true);
    let search = { _acct_id: acct._acct_id };
    const response = await appUser.functions.search_opp(search);
    setOppsList(response.result);
    setDisableOpp(false);
    setAccountInfo(acct.acct_nm);
    setAccountId(acct._acct_id);
  };

  const generateInitialSummary = async (oppId: string) => {
    enableToast();
    setLoading(true);
    const response = await appUser.functions.meetingPrep(oppId);
    setInitialSummary(response);
    disableToast();
    setLoading(false);
  };

  // const generateFinalSummary = async (oppId: string) => {
  //   enableToast();
  //   const arg = {
  //     oppId,
  //     initialSummary,
  //     additionalNotes,
  //   };

  //   const response = await appUser.functions.finalSummaryWithAdditionalNotes(
  //     arg
  //   );
  //   setFinalSummary(response);
  //   disableToast();
  // };

  const enableToast = () => {
    setProgressToastOpen(true);
    setSuccessToastOpen(false);
    setWarningToastOpen(false);
  };

  const disableToast = () => {
    setProgressToastOpen(false);
    setSuccessToastOpen(true);
    setWarningToastOpen(false);
  };

  // console.log(initialSummary);
  // console.log("accountInfo", accountInfo);
  // console.log("accountList", accountList);

  return (
    <Layout>
      <main>
        <Row className="form-hero-image">
          <Col>
            <H1 className="form-hero-text">AI Meeting Prep</H1>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> AI Meeting Prep
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <Body as="h1">
              Logged in as:{" "}
              {appUser ? appUser._profile.data.email : "Loading..."}
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <SearchInput
              id="accountName"
              className="fieldMargin"
              value={accountInfo}
              onChange={(event) => searchAccount(event)}
              aria-label="Account Name"
            >
              {accountList.map((acct: any, index) => {
                return (
                  <SearchResult onClick={() => selectAccount(acct)} key={index}>
                    {acct.acct_nm} - {acct._acct_id}
                  </SearchResult>
                );
              })}
            </SearchInput>
            <Combobox
              id="searchOpp"
              className="fieldMargin"
              disabled={disableOpp}
              label={opportunityFieldLabel}
              onChange={(value) => setSelectedOpp(value)}
              value={selectedOpp} // set the current value
            >
              {oppsList.map((opp: any, index) => (
                <ComboboxOption
                  key={index}
                  value={opp._id}
                  displayName={opp.opp_nm}
                />
              ))}
            </Combobox>
            <Button
              darkMode={true}
              disabled={loading}
              onClick={() => generateInitialSummary(selectedOpp)}
            >
              Generate Summary of historical notes for this Opp
            </Button>
            {/* <TextArea
              label="Initial Summary"
              description="Initial Summary generates after opportunity is selected.  Future version could generate on Acct ONLY and on Acct+Opp."
              onChange={(event) => {}}
              value={initialSummary}
            /> */}
            {/* <Card children={<pre>{initialSummary}</pre>} /> */}
            {loading && (
              <TigerLoader
                loadingText={
                  <>
                    Responses can take 5 sec to 5 min depending on the amount of
                    text being analyzed.
                    <br />
                    Please stand by...
                  </>
                }
              />
            )}
            {initialSummary && (
              <>
                <SummaryCard content={initialSummary} />
                <Button
                  darkMode={true}
                  disabled={false}
                  onClick={() => navigator.clipboard.writeText(initialSummary)}
                >
                  Copy
                </Button>
                {/* <TextArea
                  label="Additional Notes"
                  description=""
                  onChange={(event) => setAdditionalNotes(event.target.value)}
                  value={additionalNotes}
                />
                <Button
                  darkMode={true}
                  disabled={false}
                  onClick={() => generateFinalSummary(selectedOpp)}
                >
                  Generate Final Summary
                </Button> */}
              </>
            )}
            <div>
              {/* <TextArea
                label="Final Summary"
                description=""
                onChange={(event) => {}}
                value={finalSummary}
              /> */}
              {finalSummary && (
                <>
                  <SummaryCard content={finalSummary} />
                  <Button
                    darkMode={true}
                    disabled={false}
                    onClick={() => navigator.clipboard.writeText(finalSummary)}
                  >
                    Copy
                  </Button>
                </>
              )}

              {/* <Button
                darkMode={true}
                disabled={false}
                onClick={() =>
                  alert("Send this to LLM to produce email content")
                }
              >
                Create Summary Email
              </Button> */}
            </div>
            {accountId && (
              <div>
                <a
                  href={`https://mongodb.lightning.force.com/lightning/r/Account/${accountId}/view`}
                  target="_blank"
                >
                  Account SFDC
                </a>
              </div>
            )}
            {selectedOpp && (
              <div>
                <a
                  href={`https://mongodb.lightning.force.com/lightning/r/Opportunity/${selectedOpp}/view`}
                  target="_blank"
                >
                  Opportunity SFDC
                </a>
              </div>
            )}
            <hr />
            <Callout variant="note" title="Reminder">
              These summaries are AI generated. Please review and scrutinize the
              information appropriately for your needs.
            </Callout>

            <br />
            <br />
            <br />
          </Col>
          <Col></Col>
        </Row>
        <Toast
          variant="progress"
          title="Creating a summary of the opportunity"
          body="Sending data to the AI"
          open={progressToastOpen}
          close={() => setProgressToastOpen(false)}
        />
        <Toast
          variant="success"
          title="Success"
          body="Summary Created"
          open={successToastOpen}
          close={() => setSuccessToastOpen(false)}
        />
        <Toast
          variant="warning"
          title="Error in sending notes to AI"
          body="Error"
          open={warningToastOpen}
          close={() => setWarningToastOpen(false)}
        />
      </main>
    </Layout>
  );
};
