import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
//
import { H1 } from "@leafygreen-ui/typography";
// import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
// import { RadioBox, RadioBoxGroup } from "@leafygreen-ui/radio-box-group";
// import Card from "@leafygreen-ui/card";
// import Badge from "@leafygreen-ui/badge";
import { H2, H3, Body } from "@leafygreen-ui/typography";
import Callout from "@leafygreen-ui/callout";
import ExpandableCard from "@leafygreen-ui/expandable-card";
//
import Layout from "../../components/Layout/Layout";
// import Cards from "../../components/Cards/Cards";
// import NavCard from "./components/Cards/NavCard";
//
// import cardData from "../../utils/homePageData.json";
import { hvaCardData } from "./lib/hvaCardData";
import "../home/Home.css";

interface Props {
  isProtected: boolean;
}

interface HVACardData {
  title: string;
  description: string;
  chronology: number;
  resources: Resource[];
  filter?: string;
}

interface Resource {
  name: string;
  href: string;
}

interface SearchResults {
  title: string;
}

export const HVAComponent = ({ isProtected }: Props) => {
  const [cards, setCards] = useState<HVACardData[]>([]);
  const [selectedOption, setSelectedOption] = useState("all");
  const [cardSearchValue, setCardSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<SearchResults[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "STEM: Home";
  }, []);

  // useEffect(() => {
  //   const filteredCards = hvaCardData.filter((card) =>
  //     card.filter?.includes(selectedOption)
  //   );
  //   setCards(filteredCards);
  // }, [selectedOption]);

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  // const onSearchEvent = (event: any) => {
  //   const searchQuery = event.target.value;
  //   console.log(searchQuery);
  //   setCardSearchValue(searchQuery);

  //   const filteredResults = cards.filter((result: SearchResults) =>
  //     result.title.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  //   setSearchResults(filteredResults);
  // };

  const filterOptionLabels = [
    { text: "All", value: "all" },
    { text: "Intake Forms", value: "forms" },
    { text: "AI Help", value: "ai-help" },
    { text: "Processes", value: "processes" },
    { text: "Other", value: "other" },
  ];

  return (
    <Layout>
      <div>
        <Row className="form-hero-image">
          <Col>
            <H1 className="hero-text">SA HVAs</H1>
            <H3 className="hero-text-subtitle">High Value Activities</H3>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> SA HVAs
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <div className="p-3">
          {hvaCardData.map((card) => (
            <Col key={card.title}>
              <ExpandableCard
                title={card.title}
                description={card.description}
                flagText={card.chronology.toString()}
                darkMode={false}
              >
                {card.resources.map((resource) => (
                  <div>
                    <a href={resource.href}>{resource.name}</a>
                  </div>
                ))}
              </ExpandableCard>
            </Col>
          ))}
        </div>
        <div>
          {/* <Body>
                Reminder: Please note that this estimate is meant to provide a
                directionally accurate number. Please review with your SA for
                accuracy once completed.
              </Body> */}
          <Callout variant="note" title="Questions or Suggestions?">
            Contact the Southeast SA Team at: <b>sahil.kedar@mongodb.com</b>
          </Callout>
        </div>
        {/* <div className="p-3">
          <Row className="g-2 mt-2">
            <Cards cardData={cards} />
          </Row>
        </div> */}
        <br />
        <br />
        <br />
        <br />
      </div>
    </Layout>
  );
};
