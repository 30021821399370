import Card from "@leafygreen-ui/card";
import tiger from "../../assets/gif/tiger.gif";
import "./TigerLoader.css";

interface Props {
  loadingText?: HTMLElement | string;
}

const TigerLoader = ({ loadingText }) => {
  return (
    <Card className="tiger-loader-container">
      <img className="tiger-gif" src={tiger} alt="tiger" />
      <progress className="loading-progress-bar" value={null} />
      <div>{loadingText}</div>
    </Card>
  );
};

export default TigerLoader;
