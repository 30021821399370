import { Col, Row } from "react-bootstrap";

const Echoback = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <br />
          <div>
            <b>Audience: </b>Champion and Economic Buyer (EB)
          </div>
          <br />
          <div>
            Led by <b>rep with SL</b> in attendance
          </div>
          <br />
          <div>
            <b>Meeting Agenda:</b>
          </div>
          <ul>
            <li>Reconfirm business pain</li>
            <li>Share potential value to the business</li>
            <li>Explain required investment</li>
          </ul>

          <div>
            <b>Finalize proposal & implementation plan:</b>
          </div>
          <ul>
            <li>Work with Champion to finalize SOW (with MDB or Partner)</li>
            <li>Validate project timelines</li>
            <li>Validate PS availability</li>
          </ul>

          <div>
            <b>Build out mutual close plan with Champion </b>
          </div>
          <ul>
            <li>Legal, security, procurement</li>
            <li>Find out who else must sign off (maybe things changed!)</li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Echoback;
