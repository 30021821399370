export const hvaCardData = [
  // {
  // page: "hva",
  // section: "first",
  //   chronology: 0,
  //   title: "Template Title",
  //   description: "Template Description",
  //   resources: [
  //     {
  //       name: "First Resource Name",
  //       href: "https://www.google.com",
  //     },
  //   ],
  // },

  {
    chronology: 1,
    title: "sNWLs",
    description: "",
    resources: [
      {
        name: "What SA's can do to source NWL's",
        href: "https://docs.google.com/document/d/1v2OKBKUkuyEKUE3X-7wwdONAXuuSWsJrFhDPp2fj4PE/edit",
      },
    ],
  },

  {
    chronology: 2,
    title: "NBM Gate",
    description:
      "This is AE-led, but SAs should provide agreement that the opportunity is real and that the engagement plan matches the opportunity.",
    whoIsRequired: ["SA", "AE", "RD"],
    resources: [
      {
        name: "Value Pyramids Training 2",
        href: "https://docs.google.com/presentation/d/1h-xYXoYvKcUdMK8LTJ9PZNnOfzgSMqvrKYJ6xiGGpCU/edit#slide=id.g8a5b4d2676_0_0",
      },
      {
        name: "NBM Template: Tiger Classic",
        href: "https://docs.google.com/presentation/d/1RLZz5qLsVzq0CoFPLNLsDLj2jzzigw7esOQQ7PaaJBY/edit#slide=id.g20e5e478ad5_0_7666",
      },
      {
        name: "NBM Template: Tiger Sprint",
        href: "https://docs.google.com/presentation/d/1ZixjlbiwKie4SvX3tWwy4MHXev10MjD7Gs8vJPkfDg8/edit#slide=id.g20e67d3d019_0_10594",
      },
      {
        name: "NBM Template: Tiger Fast",
        href: "https://docs.google.com/presentation/d/1dlAHJ62MZbEb1dDXPUd5WG3EowBAAa0xr-nNC2hiaZc/edit#slide=id.g20e67d3d019_0_10594",
      },
    ],
  },

  {
    chronology: 3,
    title: "Atlas Demo",
    description:
      "This is a high-level intro demo to MongoDB Atlas led primarily through the Atlas UI. It commonly will hit the high points of MOAB (Monitoring, Optimization, Automation, and Backup). Field SAs are well equipped to handle these generally, but will leverage what’s been learned during discovery to guide the focus of the demo.",
    resources: [
      {
        name: "",
        href: "",
      },
    ],
  },

  {
    chronology: 4,
    title: "Technical Deep Dive (TDD)",
    description:
      "A Technical Deep Dive is a meeting focusing on a specific topic that solves one of your customer’s requirements.",
    resources: [
      {
        name: "Technical Deep Dives Overview (TDDs)",
        href: "https://docs.google.com/presentation/d/1gfHPAhkMriYDNjf-R5vjad9RsMrHlF9-Hvyq7B9Yf30/edit?usp=sharing",
      },
    ],
  },

  {
    chronology: 5,
    title: "Technical Feasibility Workshop (TFW)",
    description:
      "A TFW is a meeting (typically 90 minutes to 2 hours) to establish and confirm Required Capabilities and showcase MongoDB features that solve them with extra focus on differentiators",
    resources: [
      {
        name: "Library",
        href: "https://drive.google.com/drive/u/0/folders/1APngFjIXfdEaYPBrovFjksaJRf5uVXWV",
      },
      {
        name: "Technical Feasibility Workshop (TFW) Overview for AEs & SAs",
        href: "https://docs.google.com/presentation/d/1b7VA-8x-_571HMq1M70Porpm4dEsom2TCWpsYEQHdwc/edit?usp=sharing",
      },
    ],
  },

  {
    chronology: 5,
    title: "Proof of Value (PoV)",
    description:
      "Proof of Value demonstrations are used to show customers how MongoDB satisfies their Required Capabilities, typically leveraging our library PoV proofs (Github below) using mock data for their use case.",
    resources: [
      {
        name: "Github",
        href: "https://github.com/10gen/pov-proof-exercises/tree/master",
      },
      {
        name: "Deck - Proof of Value (PoV) Overview for AEs & SAs",
        href: "https://docs.google.com/presentation/d/1KksQg1jUGeDHENeIrAFhmIhNBS1PyjXIPQLY6DQGn1I/edit?usp=sharing",
      },
      {
        name: "Copy of MongoDB PoV Template - <customer> <project > - Make a copy first",
        href: "https://docs.google.com/document/d/1VhGxxOrDSeWzQRBY4Ro-BuACbcKmY8WyBKqty9btICI/edit?usp=sharing",
      },
    ],
  },

  {
    chronology: 5,
    title: "Proof of Concept (PoC)",
    description:
      "Proof of Concepts expand on PoV leveraging representative customer data and/or external integration points. Note: Be careful that PoCs scope stays bound within a measurable set of tests agreed upon in our Document of Understanding with the customer. There are cases where a MS0 is better positioned when the outcome will be closer to a prototype than meeting a checklist of requirements leveraging realistic customer data.",
    resources: [
      {
        name: "",
        href: "",
      },
    ],
  },

  {
    chronology: 5,
    title: "MongoDB Sprint Zero (MS0)",
    description:
      "A sprint Zero (MS0) is kicked off with a meeting at the start of a customer's sprint intended to give the customer enough help to start building on MongoDB ASAP. There will be regular follow-ups during the timeboxed sprint and could end with an echoback to the customer’s stakeholders.",
    resources: [
      {
        name: "What is a MongoDB Sprint Zero (MS0)?",
        href: "https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1bf0244133e_1_4721",
      },
      {
        name: "Winston’s video from Tiger training",
        href: "",
      },
      {
        name: "Link to example repo",
        href: "",
      },
    ],
  },

  {
    chronology: 6,
    title: "Office Hours",
    description:
      "These are 30-60 minute sessions with an existing customer to bring developer questions. Ideally, the questions can be mostly sourced beforehand to give the SAs time to prepare, but can include ad hoc questions. Note: Be careful that these don’t attempt to replace usage of Technical Services support cases or Professional Services consulting engagements.",
    resources: [
      {
        name: "",
        href: "",
      },
    ],
  },

  {
    chronology: 6,
    title: "Design Review",
    description:
      "This is DevRel-led, but SAs will qualify if the workload needs a design review and gather the info requested.",
    resources: [
      {
        name: "Requests for DevRel",
        href: "https://sites.google.com/mongodb.com/fy24-strategic-devrel-programs/request-support?authuser=0",
      },
      {
        name: "DevRel Info",
        href: "https://sites.google.com/mongodb.com/fy24-strategic-devrel-programs/home",
      },
      {
        name: "How to engage with DevRel (spreadsheet)",
        href: "https://docs.google.com/spreadsheets/d/1NwB6-WWhHCGI0x8TKK3A7CdOsfcbVVQrdL4B36eYjoA/edit#gid=931689251",
      },
    ],
  },

  {
    chronology: 6,
    title: "Enablement",
    description:
      "These are typically hour-long educational sessions focused around a certain topic area within MongoDB (For example, Indexing Best practices, Monitoring and Alerting, etc.). The purpose is to raise our customer’s knowledge of our products and expose them to more capabilities they don’t have familiarity with.",
    resources: [
      {
        name: "",
        href: "",
      },
    ],
  },

  {
    chronology: 7,
    title: "Business Value Assessment (BVA)",
    description:
      "This is BVC-led, but SAs will have significant input in building BVAs. SAs own the Atlas scoping and verification of competitive pricing comparisons.",
    resources: [
      {
        name: "Business Value Consulting (BVC) Highspot",
        href: "https://mongodb.highspot.com/spots/5ae887bf3e255f72e6f4b0d2",
      },
    ],
  },

  {
    chronology: 7,
    title: "Professional Services Statement of Work (PSSW)",
    description: "",
    resources: [
      {
        name: "",
        href: "",
      },
    ],
  },
];
