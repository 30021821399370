import { useEffect, useState } from "react";
import * as Realm from "realm-web";
import { useOktaAuth } from "@okta/okta-react";
import { app } from "../../realmApp/realmApp";
// import { useNavigate } from "react-router-dom";
import "./index.css";
import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
// Components - Leafy
import { H1, Body } from "@leafygreen-ui/typography";
import Button from "@leafygreen-ui/button";
import TextArea from "@leafygreen-ui/text-area";
import Toast from "@leafygreen-ui/toast";
import Callout from "@leafygreen-ui/callout";
// Components - custom/internal - AI Notes
import SummaryCard from "./components/SummaryCard";
// Components - custom/internal - General
import TigerLoader from "../../components/Loading/TigerLoader";

interface Props {
  isProtected: boolean;
}

export const NotesAnalyzer = ({ isProtected }: Props) => {
  // const navigate = useNavigate();
  const [notes, setNotes] = useState("");
  const [finalSummary, setFinalSummary] = useState("");

  // Toast/Progress state
  const [progressToastOpen, setProgressToastOpen] = useState(false);
  const [successToastOpen, setSuccessToastOpen] = useState(false);
  const [warningToastOpen, setWarningToastOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Get the user from the context
  const [user, setUser] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  // This is the user used to invoke the functions
  const [appUser, setAppUser] = useState(null);

  // Set the page title
  useEffect(() => {
    document.title = "STEM: Notes Summary";
  }, []);

  // App Services/Realm functions
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUser(info);
        loginApiKey();
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const loginApiKey = () => {
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);
    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
    });
  };

  // Function to generate the summary of the notes with the AI
  const generateNotesSummary = async (notes: string) => {
    enableToast();
    setLoading(true);
    const response = await appUser.functions.summarizeNotes(notes);
    setFinalSummary(response);
    disableToast();
    setLoading(false);
  };

  // Toggle ProgressToast ON
  const enableToast = () => {
    setProgressToastOpen(true);
    setSuccessToastOpen(false);
    setWarningToastOpen(false);
  };
  // Toggle ProgressToast OFF
  const disableToast = () => {
    setProgressToastOpen(false);
    setSuccessToastOpen(true);
    setWarningToastOpen(false);
  };

  return (
    <Layout>
      <main>
        <Row className="form-hero-image">
          <Col>
            <H1 className="form-hero-text">AI Notes Analyzer</H1>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> AI Notes Analyzer
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <Body as="h1">
              Logged in as:{" "}
              {appUser ? appUser._profile.data.email : "Loading..."}
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <>
              <TextArea
                className="notes-textarea"
                label="Notes"
                description="Copy/paste notes here"
                rows={10}
                onChange={(event) => setNotes(event.target.value)}
                value={notes}
              />
              <Button
                className="input-submit-button"
                darkMode={true}
                disabled={loading || !notes}
                onClick={() => generateNotesSummary(notes)}
              >
                Generate Notes Anaylsis
              </Button>
              <br />
              <br />
            </>
            {loading && (
              <TigerLoader
                loadingText={
                  <>
                    Responses can take 5 sec to 5 min depending on the amount of
                    text being analyzed.
                    <br />
                    Please stand by...
                  </>
                }
              />
            )}
            {finalSummary && (
              <div>
                <>
                  <SummaryCard content={finalSummary} />
                  <Button
                    darkMode={true}
                    disabled={false}
                    onClick={() => navigator.clipboard.writeText(finalSummary)}
                  >
                    Copy summary to clipboard
                  </Button>
                </>

                {/* <Button
                darkMode={true}
                disabled={false}
                onClick={() =>
                  alert("Send this to LLM to produce email content")
                }
              >
                Create Summary Email
              </Button> */}
              </div>
            )}
            <hr />
            <Callout variant="note" title="Reminder">
              These summaries are AI generated. Please review and scrutinize the
              information appropriately for your needs.
            </Callout>
            <br />
            <br />
            <br />
            <br />
          </Col>
          <Col></Col>
        </Row>
        <Toast
          variant="progress"
          title="Creating a summary of the notes"
          body="Sending data to the AI"
          open={progressToastOpen}
          close={() => setProgressToastOpen(false)}
        />
        <Toast
          variant="success"
          title="Success"
          body="Summary Created"
          open={successToastOpen}
          close={() => setSuccessToastOpen(false)}
        />
        <Toast
          variant="warning"
          title="Error in sending notes to AI"
          body="Error"
          open={warningToastOpen}
          close={() => setWarningToastOpen(false)}
        />
      </main>
    </Layout>
  );
};
