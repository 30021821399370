import { Col, Row } from "react-bootstrap";

const Validation = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <br />
          <div>
            <b>Execute technical validation</b>
          </div>
          <ul>
            <li>POV, demos, customer reference calls</li>
            <li>Engage relevant Partners as needed</li>
          </ul>

          <div>
            <b>
              Champion helps build business validation that could be (one of):
            </b>
          </div>
          <ul>
            <li>Comprehensive 3 Whys</li>
            <li>TCO comparison</li>
            <li>Full BVA</li>
            <li>
              POV playback where customer agrees technical validation is
              completed successfully
            </li>
          </ul>

          <div>
            <b>Comprehensive 3 Whys:</b>
          </div>
          <ul>
            <li>
              Strong understanding of “Why Anything” using metrics in the EBs
              language
            </li>
            <li>“Why MDB”, aligned with ”Why Anything”</li>
            <li>Strong “Why now” with true compelling event</li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>

          <div>EM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1QxWeFTWruTGrFW4P7As5D5qS1VJrTZWBI3ttHQNaED4/edit#slide=id.g2aceb6a94f1_0_6095">
                FY25 PS GTM Academy
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Validation;
