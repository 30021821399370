import { useEffect, useState } from "react";
import * as Realm from "realm-web";
import { useOktaAuth } from "@okta/okta-react";
import { app } from "../../realmApp/realmApp";
// import { useNavigate } from "react-router-dom";
import "./index.css";
import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
// Components - Leafy
import { H1, Body, Link } from "@leafygreen-ui/typography";
import ExpandableCard from "@leafygreen-ui/expandable-card";
// Components - custom/internal
import { Hero } from "../../components/Layout/Hero/Hero";

interface Props {
  isProtected: boolean;
}

export const ManagerDashboard = ({ isProtected }: Props) => {
  // const navigate = useNavigate();

  // Get the user from the context
  const [user, setUser] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  // This is the user used to invoke the functions
  const [appUser, setAppUser] = useState(null);
  // Submission tickets state
  const [newTickets, setNewTickets] = useState([]);
  // Manager user verification state
  const [managerUser, setManagerUser] = useState("rosston.ritter@mongodb.com");

  useEffect(() => {
    document.title = "STEM: AdminDash";
  }, []);

  // App Services/Realm functions
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUser(info);
        loginApiKey();
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const loginApiKey = () => {
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);
    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
    });
  };

  useEffect(() => {
    try {
      if (appUser) {
        getNewTickets();
      } else {
        return;
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  }, [appUser]);

  const getNewTickets = async () => {
    const result = await appUser.functions.getNewTickets();
    setNewTickets(result.result);
  };

  return (
    <Layout>
      <main>
        <Hero title="Manager Dashboard" breadcrumbText="Manager Dashboard" />

        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <Body as="h1">
              Logged in as:{" "}
              {appUser ? appUser._profile.data.email : "Loading..."}
            </Body>
            <hr />
            {appUser?._profile.data.email === managerUser ? (
              <>
                <Body>New Tickets: </Body>
                {newTickets
                  ? newTickets.map((ticket) => (
                      <ExpandableCard
                        key={ticket._id}
                        title={ticket.user.name}
                        description={`Submitted: ${ticket.timestamp}`}
                        flagText={ticket.originalTicket["Customer name"]}
                        darkMode={false}
                      >
                        <Body>{ticket.originalTicket.whyNow}</Body>
                        <Body>{ticket.originalTicket.whyAnything}</Body>
                        <Body>{ticket.originalTicket.whyMongoDB}</Body>
                      </ExpandableCard>
                    ))
                  : "None"}
              </>
            ) : (
              "Not authorized to view this content"
            )}
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}></Col>
          <Col></Col>
        </Row>
      </main>
    </Layout>
  );
};
