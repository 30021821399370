import { Col, Row } from "react-bootstrap";

const Scope = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>
            <b>TFW to uncover required capabilities and build consensus</b>
          </div>
          <ul>
            <li>Engagement manager attends</li>
          </ul>
          <div>Execute required Technical Deep Dives</div>
          <br />
          <div>
            <b>Build technical Champions</b>
          </div>
          <br />
          <div>
            Provide approximate <b>sizing</b> if needed
          </div>
          <br />
          <div>
            <b>Refine Project timelines</b>
          </div>
          <br />
          <div>
            <b>Build & test Champion</b>
          </div>
          <ul>
            <li>Evidence of influence over EB</li>
            <li>Start building 3 Whys</li>
            <li>Rehearse with RD prior to go/no-go</li>
            <li>Specific prep around cost & proposal conversation</li>
            <li>
              Discover & document <b>decision & paper process</b>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1b7VA-8x-_571HMq1M70Porpm4dEsom2TCWpsYEQHdwc/edit#slide=id.g60718f3d26_0_0">
                Technical Feasibility Workshop (TFW) Overview
              </a>
            </li>
          </ul>
          <div>SA</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1b7VA-8x-_571HMq1M70Porpm4dEsom2TCWpsYEQHdwc/edit#slide=id.g60718f3d26_0_0">
                Technical Feasibility Workshop (TFW) Overview
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1RCX4WnfwqFXLXUlUmZLwqUQORIGz05IdQyvtWcFhXdA/edit#slide=id.g3b76311eb6_5_2685">
                Effective Required Capabilities Overview
              </a>
            </li>
          </ul>
          <div>SA</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1K0IEUlVHCHdQ3XZZTbZIUZZ470s2dCMxKjxYuSYTMMo/edit#slide=id.g6071369b62_0_0">
                TFW Further Info for SAs
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1RCX4WnfwqFXLXUlUmZLwqUQORIGz05IdQyvtWcFhXdA/edit#slide=id.g3b76311eb6_5_2685">
                Effective Required Capabilities Overview
              </a>
            </li>
          </ul>
          <div>EM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1QxWeFTWruTGrFW4P7As5D5qS1VJrTZWBI3ttHQNaED4/edit#slide=id.g2aceb6a94f1_0_6095">
                FY25 PS GTM Academy
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Scope;
