import { Col, Row } from "react-bootstrap";

const GoNoGo = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <br />
          <div>
            <b>Audience: </b>Economic Buyer (EB)
          </div>
          <br />
          <div>
            Led by <b>rep with SL</b> in attendance & supported by{" "}
            <b>Champion</b>
          </div>
          <br />
          <div>
            <b>Agenda:</b>
          </div>
          <ul>
            <li>Present understanding of business & technical pain</li>
            <li>Validate the urgency & priority for EB to resolve</li>
            <li>Validate the EB is the true EB</li>
            <li>Confirm decision process & validation plan (w/ timelines)</li>
            <li>
              Discuss how they will measure success (foundation for business
              case)
            </li>
            <li>
              Agree we will proceed with implementation if we can prove success{" "}
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GoNoGo;
