import Card from "@leafygreen-ui/card";
import "./index.css";

const SummaryCard = ({ content }) => {
  return (
    <Card className="card-styles" as="article">
      {content}
    </Card>
  );
};

export default SummaryCard;
