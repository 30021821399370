import { Col, Row } from "react-bootstrap";

const Validation = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <br />
          <div>
            <b>Conduct MS0 workshop </b>(technical validation) as led by SA team
            and/or TCO calculation
          </div>

          <div>
            <b>Audience for Initial Workshop</b>
          </div>
          <ul>
            <li>
              <b>Customer:</b> Champion, SA & Development Team
            </li>
            <li>
              <b>MongoDB:</b> rep & SAValidate “leaning-in”
            </li>
          </ul>

          <div>
            <b>Developer-led </b>whiteboarding & hands-on prototyping
          </div>
          <br />

          <div>
            <b>MongoDB-led </b>SA & EM
          </div>
          <ul>
            <li>Enablement & bootstrapping developers to Atlas,</li>
            <li>Implementation considerations</li>
            <li>Services offerings</li>
          </ul>

          <div>
            <b>Plan & monitor the MS0 : maximum 10 days of prototype support</b>
          </div>
          <ul>
            <li>Asynchronous Activity</li>
            <li>Periodic Check-ins (Support Customer Development efforts)</li>
            <li>
              MS0 is meant to help the customer self-actualize and generate
              meaningful code
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>

          <div>SA</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8">
                Tiger Training (Pre-onboarding & MS0)
              </a>
            </li>
          </ul>

          <div>EM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1QxWeFTWruTGrFW4P7As5D5qS1VJrTZWBI3ttHQNaED4/edit#slide=id.g2aceb6a94f1_0_6095">
                FY25 PS GTM Academy
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Validation;
