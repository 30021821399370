export const callFunction = async (
  user: Realm.User | null,
  name: string,
  payload?: any
): Promise<any> => {
  console.log(
    `Entering: function ${name} is being called with payload`,
    payload
  );
  const response: any = await user?.callFunction(name, payload);
  console.log(`Exiting: function ${name} returned`, response);
  return response;
};

// Get end date to the nearest 15 minutes
export const getNearestFutureEndQuarterHour = (date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();
  const totalMinutes =
    hour * 60 + minutes + seconds / 60 + milliseconds / 60000;

  const roundedTotalMinutes = Math.ceil(totalMinutes / 15) * 15 + 15;

  const nearestFutureQuarterHour = new Date(date);
  nearestFutureQuarterHour.setHours(Math.floor(roundedTotalMinutes / 60));
  nearestFutureQuarterHour.setMinutes(roundedTotalMinutes % 60);
  nearestFutureQuarterHour.setSeconds(0);
  nearestFutureQuarterHour.setMilliseconds(0);

  return nearestFutureQuarterHour;
};

// Get start date to the nearest 15 minutes
export const getNearestFutureQuarterHour = (date) => {
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const milliseconds = date.getMilliseconds();
  const totalMinutes =
    hour * 60 + minutes + seconds / 60 + milliseconds / 60000;

  const roundedTotalMinutes = Math.ceil(totalMinutes / 15) * 15;

  const nearestFutureQuarterHour = new Date(date);
  nearestFutureQuarterHour.setHours(Math.floor(roundedTotalMinutes / 60));
  nearestFutureQuarterHour.setMinutes(roundedTotalMinutes % 60);
  nearestFutureQuarterHour.setSeconds(0);
  nearestFutureQuarterHour.setMilliseconds(0);

  return nearestFutureQuarterHour;
};

// Get Weekyday
export const isWeekday = (date: Date): boolean => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};
