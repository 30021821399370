import { Col, Row } from "react-bootstrap";

const Discovery = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>SA Verifies Command of the Message (CoM) Fundamentals</div>
          <ul>
            <li>Before Scenario</li>
            <li>Negative Consequences</li>
            <li>After Scenario</li>
            <li>PBO (optional)</li>
            <li>RCs (Why MongoDB)</li>
            <li>Value Driver(s)</li>
          </ul>

          <div>Understand the NWL</div>
          <ul>
            <li>Single NWL or Multiple NWLs</li>
            <li>High Level Sizing</li>
            <li>Use Case</li>
            <li>Sales Motion</li>
          </ul>
          <div>Urgency</div>
          <br />
          <div>Competitiveness</div>
          <ul>
            <li>
              <a href="https://mongodb.highspot.com/spots/5d80e8383f65f62a946a266a">
                Competition Highspot
              </a>
            </li>
            <li>
              Slack{" "}
              <a href="https://mongodb.enterprise.slack.com/archives/C0V2TN908">
                #competition
              </a>{" "}
              channel
            </li>
          </ul>
          <div>Decision Process</div>
          <br />
          <div>Identify Key People</div>
          <ul>
            <li>Coaches</li>
            <li>Potential Champions</li>
            <li>Enemies</li>
            <li>Economic Buyer</li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
          <div>AE/SA/CSM/EM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/document/d/1WVytzc-LIqRrxJzkofktj23GtmewqteFcuR_FPDqav0/edit">
                Discovery Capture Sheet
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/5d08128ec79c52759860cac4#1">
                Tiger Sales Process Playbook
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/65241a9d650fbb2f9b7d05ac">
                Value Cards
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/5ef6049ea2e3a944f44333fd#1">
                CoM Focused Discovery Questions
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8">
                Pre-onboarding & MS0
              </a>
            </li>
          </ul>
          <div>SA</div>
          <ul>
            <li>
              <a href="https://docs.google.com/spreadsheets/d/1Mn_lRdSKhN2fWc6ZQ70rN1ehKP3rCLCN7fjTrRc8tp4/edit#gid=249721064">
                Example Discovery Questions
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
            <li>
              <a href="https://mongodb.highspot.com/items/5d08128ec79c52759860cac4#1">
                Tiger Sales Process Playbook
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8">
                SA Tiger Training
              </a>
            </li>
            <li>
              <a href="https://docs.google.com/presentation/d/1TTRwcjsLm4te7V7OclVSke1F-UJzmSToFAwdUVWVYHs/edit#slide=id.g1d790b1c199_0_8">
                Pre-onboarding & MS0
              </a>
            </li>
          </ul>

          <div>CSM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
          </ul>

          <div>EM</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1yZQ_K8005NPt6QewqFMYoTCQw_8Ct7qTOTTVjXGZkwM/edit#slide=id.g1f2346fb413_0_0">
                FY24 New Workloads - Growth Enablement
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Discovery;
