import { Col, Row } from "react-bootstrap";

const NBMGate = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>Goal of NBM</div>
          <ul>
            <li>Qualify Champion</li>
            <li>Validate “leaning-in”</li>
            <li>Validate importance of NWL</li>
            <li>Validate urgency to start</li>
            <li>Validate remaining objections</li>
            <li>Validate & agree on engagement model</li>
          </ul>
          <div>
            <b>Use CoM Summary</b> to get credibility that we understand their
            NWL
          </div>

          <br />

          <div>
            <b>Use Relevant Proof Points and/or Customer Story</b> (SA to
            support) to sell the value of MS0
          </div>
          <br />

          <div>How to Close NBM</div>
          <ul>
            <li>
              Agree on <b>Sprint 0</b> workshop date and required participants
            </li>
            <li>Agree on showcase review date and participants/groups</li>
          </ul>

          <div>Attendance</div>
          <ul>
            <li>SA</li>
            <li>Rep</li>
            <li>RD (optional)</li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1ZixjlbiwKie4SvX3tWwy4MHXev10MjD7Gs8vJPkfDg8/edit#slide=id.g20e67d3d019_0_10594">
                NBM Template: Tiger Sprint
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1h-xYXoYvKcUdMK8LTJ9PZNnOfzgSMqvrKYJ6xiGGpCU/edit#slide=id.g8a5b4d2676_0_0">
                Value Pyramids Training (AE)
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default NBMGate;
