import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
//
// import { H1 } from "@leafygreen-ui/typography";
import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
import { RadioBox, RadioBoxGroup } from "@leafygreen-ui/radio-box-group";
import Card from "@leafygreen-ui/card";
import Badge from "@leafygreen-ui/badge";
import { H1, H2, H3, Body } from "@leafygreen-ui/typography";
import Callout from "@leafygreen-ui/callout";
import { InfoSprinkle } from "@leafygreen-ui/info-sprinkle";

//
import Layout from "../../components/Layout/Layout";
// import Cards from "../../components/Cards/Cards";
// import NavCard from "./components/Cards/NavCard";
//
// import cardData from "../../utils/homePageData.json";
import { navCardData } from "./lib/navCardData";
//
import Modal from "@leafygreen-ui/modal";
import Button from "@leafygreen-ui/button";
// import { PDFViewer } from "@react-pdf/renderer";
// import { MyDocument } from "./components/PDF/StemSiteInfo";
//
import "../home/Home.css";

/*
 * Google Analytics: Report a custom event
ReactGA.event({
  category: "your category",
  action: "your action",
  label: "your label", // optional
  value: 99, // optional, must be a number
  nonInteraction: true, // optional, true/false
  transport: "xhr", // optional, beacon/xhr/image
});
*/

interface Props {
  isProtected: boolean;
}

interface CardData {
  title: string;
  text: string;
  img: string;
  href: string;
  leadTime?: string;
  target?: string;
  salesSegment?: string[];
  cardLink?: string;
}

interface SearchResults {
  title: string;
}

export const HomeComponent = ({ isProtected }: Props) => {
  const [cards, setCards] = useState<CardData[]>([]);
  const [selectedOption, setSelectedOption] = useState("all");
  const [cardSearchValue, setCardSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<SearchResults[]>([]);
  const [openStemSiteInfoModal, setOpenStemSiteInfoModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "STEM: Home";
  }, []);

  useEffect(() => {
    const filteredCards = navCardData.filter((card) =>
      card.filter?.includes(selectedOption)
    );
    setCards(filteredCards);
  }, [selectedOption]);

  const handleChange = (event: any) => {
    setSelectedOption(event.target.value);
  };

  const onSearchEvent = (event: any) => {
    const searchQuery = event.target.value;
    console.log(searchQuery);
    setCardSearchValue(searchQuery);

    const filteredResults = cards.filter((result: SearchResults) =>
      result.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const filterOptionLabels = [
    { text: "All", value: "all" },
    { text: "Intake Forms", value: "forms" },
    { text: "AI Help", value: "ai-help" },
    { text: "Processes", value: "processes" },
    { text: "Other", value: "other" },
  ];

  return (
    <Layout>
      <div>
        <Row className="hero-image">
          <Col>
            <div className="hero-items">
              <H1 className="hero-text">
                STEM : Solutions, Technology, and Expertise Matrix
              </H1>
              <Body className="hero-text-version-subtitle">{`\<Beta version 0.5\>`}</Body>
              {/* <Body className="hero-text-version-subtitle"></Body> */}
              <Button
                size="xsmall"
                onClick={() => setOpenStemSiteInfoModal((curr) => !curr)}
              >
                What is the STEM site?
              </Button>
              <Modal
                size="large"
                open={openStemSiteInfoModal}
                setOpen={setOpenStemSiteInfoModal}
              >
                <div className="stem-info-container">
                  <img
                    src={require("../../assets/STEM Site One_Two Pager_DRAFT.png")}
                  />
                  <img
                    src={require("../../assets/STEM Site One_Two Pager_DRAFT (1).png")}
                  />
                </div>
              </Modal>
              <H3 className="hero-text-subtitle">
                Quick access to the Sales Ecosystem. Use tiles below to plan
                your next meeting.
              </H3>
              <SearchInput
                className="searchInputStyle"
                size="large"
                value={cardSearchValue}
                onChange={(event) => onSearchEvent(event)}
                aria-label="some label"
              >
                {searchResults.map((query: any, index) => {
                  return (
                    <SearchResult
                      onClick={() => {
                        // navigate(`${query.href}`);
                        window.open(query.href, "_self");
                      }}
                      key={index}
                    >
                      {query.title}
                    </SearchResult>
                  );
                })}
              </SearchInput>
              <p className="radio-box-text">
                Filter offerings based upon what you need to do...
              </p>
              <RadioBoxGroup
                onChange={handleChange}
                className="radio-box-group-default radioButtons"
                size="compact"
                value={selectedOption}
              >
                {filterOptionLabels.map((item) => (
                  <RadioBox
                    className="radio-button"
                    key={item.value}
                    value={item.value}
                  >
                    {item.text}
                  </RadioBox>
                ))}
                {/* <RadioBox className="radio-button" value="growth">
                  Growth
                </RadioBox>
                <RadioBox className="radio-button" value="rsc">
                  RSC
                </RadioBox>
                <RadioBox className="radio-button" value="specialist">
                  Specialist
                </RadioBox>
                <RadioBox className="radio-button" value="bvc">
                  BVC
                </RadioBox>
                <RadioBox className="radio-button" value="all">
                  All
                </RadioBox>
                <RadioBox className="radio-button" value="test">
                  Test
                </RadioBox> */}
              </RadioBoxGroup>
            </div>
          </Col>
        </Row>
        <div className="p-3">
          <Row className="g-2 mt-2">
            {cards.map((card) => (
              <Col key={card.title} xs={12} sm={6} md={4} lg={2}>
                <Card
                  className="home-tile-card"
                  onClick={() => {
                    window.open(card.href, "_self");
                    // window.open(card.href, "_blank");
                  }}
                >
                  <div className="embed-responsive embed-responsive-16by9">
                    <img
                      className="card-img-top embed-responsive-item"
                      src={require(`../../assets/${card.img}`)}
                      alt=""
                    />
                  </div>

                  <H3 className="title">{card.title}</H3>
                  <Body className="body">{card.text}</Body>
                  {card.leadTime && (
                    <Row>
                      <Col>
                        <Badge variant="yellow" className="my-badge">
                          Lead time: {card.leadTime}
                        </Badge>
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </div>
        <div>
          {/* <Body>
                Reminder: Please note that this estimate is meant to provide a
                directionally accurate number. Please review with your SA for
                accuracy once completed.
              </Body> */}
          <Callout variant="note" title="Questions or Suggestions?">
            Contact the Southeast SA Team at: <b>sahil.kedar@mongodb.com</b>
          </Callout>
        </div>
        {/* <div className="p-3">
          <Row className="g-2 mt-2">
            <Cards cardData={cards} />
          </Row>
        </div> */}
        <br />
        <br />
        <br />
        <br />
        {/* <PDFViewer>
          <MyDocument />
        </PDFViewer> */}
      </div>
    </Layout>
  );
};
