import { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { app } from "../../realmApp/realmApp";
import * as Realm from "realm-web";
import DatePicker from "react-datepicker";
import addDays from "date-fns/addDays";
// Layout components
import { Col, Row } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";
// Leafy components
import { H1, Body, Label, Link } from "@leafygreen-ui/typography";
import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import TextInput from "@leafygreen-ui/text-input";
import Button from "@leafygreen-ui/button";
import Modal from "@leafygreen-ui/modal";
import Badge from "@leafygreen-ui/badge";
import Checkbox from "@leafygreen-ui/checkbox";
import Callout from "@leafygreen-ui/callout";
//
import { isWeekday, getNearestFutureQuarterHour } from "../../utils/functions";
import { emailToOptions } from "./lib/emailList";

// styles
import "../forms/form.css";

// type interfaces
interface Props {
  isProtected: boolean;
}

// RequestFormSA component
export const RequestFormSA = ({ isProtected }: Props) => {
  // User State
  // Get the user from the context
  const [user, setUser] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  // This is the user used to invoke the functions
  const [appUser, setAppUser] = useState(null);

  // Alert State
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  // Search - Account/Opp State
  // Account
  const [accountInfo, setAccountInfo] = useState({ name: "", sfdc_id: "" });
  const [accountId, setAccountId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountList, setAccountList] = useState([]);
  // Opportunity
  const [oppInfo, setOppInfo] = useState({ name: "", sfdc_id: "" });
  const [oppName, setOppName] = useState("");
  const [oppId, setOppId] = useState("");
  const [oppsList, setOppsList] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState("");
  const [disableOpp, setDisableOpp] = useState(true);
  // const [oppList, setOppList] = useState([]);

  // Three Whys - Form Input State
  const [whyNow, setWhyNow] = useState("");
  const [whyAnything, setWhyAnything] = useState("");
  const [whyMongoDB, setWhyMongoDB] = useState("");

  // Validation states
  const [isAccountSelected, setIsAccountSelected] = useState(false);

  // Date input State
  const [dateOptionalHidden, setDateOptionalHidden] = useState(false);
  const [isOptionalDateChecked, setOptionalDateChecked] = useState(false);
  // const [leadTimeText, setLeadTimeText] = useState<string>("2+ days");
  const [leadTime, setLeadTime] = useState(2);
  const [meetingLeadTime, setMeetingLeadTime] = useState(2);
  const [prepLeadTime, setPrepLeadTime] = useState(1);
  const [startDate, setStartDate] = useState(() => {
    const currentDate = new Date(); // Get the current date
    currentDate.setDate(currentDate.getDate() + leadTime); // Add leadTime days to the current date
    return currentDate; // Return the updated start date
  });
  const [meetingDate, setMeetingDate] = useState(() => {
    const currentDate = new Date(); // Get the current date
    currentDate.setDate(currentDate.getDate() + meetingLeadTime); // Add leadTime days to the current date
    return currentDate; // Return the updated start date
  });
  const [prepDate, setPrepDate] = useState(() => {
    const currentDate = new Date(); // Get the current date
    currentDate.setDate(currentDate.getDate() + prepLeadTime); // Add leadTime days to the current date
    return currentDate; // Return the updated start date
  });
  const [startTime, setStartTime] = useState(
    getNearestFutureQuarterHour(new Date())
  );

  // Other state
  // Search email / CC  List
  const [emailList, setEmailList] = useState([]);
  const [sendToEmailList, setSendToEmailList] = useState([]);
  const [emailTo, setEmailTo] = useState([]);
  const [emailCache, setEmailCache] = useState({});
  const [extraEmailsValue, setExtraEmailsValue] = useState([]);

  // Form State
  // const [form, setForm] = useState({
  //   requestType: "sa",
  //   //
  //   accountInfo,
  //   oppInfo,
  //   requester: {
  //     name: appUser?._profile?.data?.name || "",
  //     email: appUser?._profile?.data?.email || "",
  //   },
  //   whyNow,
  //   whyAnything,
  //   whyMongoDB,
  //   prepDate,
  //   meetingDate: startDate,
  //   emailTo,
  //   // accountName,
  //   // accountId,
  //   // oppName,
  // });

  // Modal state
  const [openInfoModal, setOpenInfoModal] = useState(false);

  ////// EFFECTS //////
  //Set page title
  useEffect(() => {
    document.title = "STEM: SA Request Form";
  }, []);

  // Check auth and set user
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.getUser().then((info) => {
        console.log(info);
        setUser(info);
        loginApiKey();
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  // Set form state
  // useEffect(() => {
  //   setForm({
  //     ...form,
  //     requester: {
  //       name: user?.name || "",
  //       email: user?.email || "",
  //     },
  //     accountInfo,
  //     oppInfo,
  //     whyNow,
  //     whyAnything,
  //     whyMongoDB,
  //     prepDate,
  //     meetingDate: startDate,
  //     emailTo,
  //     // accountName,
  //     // accountId,
  //     // oppName,
  //   });
  // }, [
  //   accountInfo,
  //   oppInfo,
  //   whyNow,
  //   whyAnything,
  //   whyMongoDB,
  //   prepDate,
  //   startDate,
  //   user,
  //   emailTo,
  //   // accountName,
  //   // accountId,
  //   // oppName,
  // ]);

  ////// FUNCTIONS //////
  // Login with API Key (Realm)
  const loginApiKey = () => {
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);
    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
    });
  };

  // const handleChange = (e: any) => {
  //   setForm({ ...form, [e.target.name]: e.target.value });
  // };

  // Search Account
  const searchAccount = async (event: any) => {
    if (isAccountSelected) {
      // Reset the flag once we've ignored one change.
      setIsAccountSelected(false);
      return;
    }
    const searchQuery = event.target.value;
    if (user === undefined) return;
    setAccountName(searchQuery);
    if (searchQuery.length < 3) {
      setAccountList([]);
      return;
    }
    let search = { searchTerm: searchQuery };
    const response = await appUser.functions.search_account(search);
    setAccountList(response.result);
    //clean the opp field
    setSelectedOpp("");
  };

  // Select Account in SearchInput
  const selectAccount = async (acct: any) => {
    setIsAccountSelected(true);
    let search = { _acct_id: acct._acct_id };
    const response = await appUser.functions.search_opp(search);
    setOppsList(response.result);
    setDisableOpp(false);
    setAccountName(acct.acct_nm);
    setAccountId(acct._acct_id);
    setAccountInfo({ name: acct.acct_nm, sfdc_id: acct._acct_id });
  };

  // Select Opportunity in SearchInput Dropdown
  const selectOpp = (opp: any) => {
    setOppName(opp.opp_nm);
    setOppId(opp._id);
    let obj = JSON.parse(opp);
    setOppInfo(obj);
  };

  // Form Object
  const form = {
    requestType: "sa",
    accountInfo,
    oppInfo,
    requester: {
      name: user?.name || "",
      email: user?.email || "",
    },
    whyNow,
    whyAnything,
    whyMongoDB,
    prepDate: isOptionalDateChecked ? null : prepDate,
    meetingDate: isOptionalDateChecked ? null : meetingDate,
    emailTo,
  };

  // Submit Form - Button Click
  const handleSubmit = async () => {
    if (user === undefined) return;
    // Call the Realm Function "create_zendesk_ticket"
    const submitResult = await appUser.functions.ticket_submission(form);
    const emailResult = await appUser.functions.sendEmail_testRR(form);

    if (submitResult.status === 200 && emailResult.status === "200 OK") {
      alert("Submitted to DB and Email sent!");
      // setProgressToastOpen(false);
      // setSuccessToastOpen(true); // set state to true on success
      // setTicketID(
      //   `https://help-sales.mongodb.com/hc/en-us/requests/${result.ticketId}`
      // );
    } else {
      alert("Error");
      // setProgressToastOpen(false);
      // setWarningToastOpen(true);
      // const errorBody = result.zendeskResponse.body;
      // const jsonObject = JSON.parse(errorBody);
      // setTicketWarning(`Error - ${jsonObject.details.base[0].description}`);
    }
  };

  // Search by Email
  const searchEmail = async (searchQuery: any) => {
    if (user === undefined) return;
    if (searchQuery.length < 3) {
      setEmailList([]);
      return;
    }
    let search = { searchTerm: searchQuery };
    const response = await appUser.functions.search_by_email(search);
    let emails = [];
    response.result.forEach((email) => {
      const concatName = `${email.firstName} ${email.lastName}`;
      emails.push(concatName);
      let cacheObject = emailCache;
      cacheObject[concatName] = email.username;
      setEmailCache(cacheObject);
    });

    setEmailList(emails);
  };

  const handleExtraEmailField = (e) => {
    // setExtraEmailsValue(e);
    setEmailList(e);
  };

  // console.log("user", user);
  // console.log("appUser", appUser);
  // console.log("accountName", accountName);
  // console.log("oppInfo", oppInfo);
  // console.log("accountInfo", accountInfo);
  // console.log("sendToEmailList", sendToEmailList);
  // console.log("startDate -> meetingDate", new Date(startDate).toDateString());
  console.log("form", form);

  return (
    <Layout>
      <div>
        <Row className="form-hero-image">
          <Col>
            <H1 className="form-hero-text">Request an SA</H1>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> Request an SA
            </Body>
          </Col>
          <Col></Col>
        </Row>

        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <div>
              <Body as="h1">
                Logged in as:{" "}
                {appUser ? appUser._profile.data.email : "Loading..."}
              </Body>
              {/* Search Input - Account */}

              <Label className="fieldMargin" htmlFor="accountName">
                Account Name *
              </Label>
              <SearchInput
                id="accountName"
                className="fieldMargin"
                value={accountName}
                onChange={(event) => searchAccount(event)}
                aria-label="Account Name"
              >
                {accountList.map((acct: any, index) => {
                  return (
                    <SearchResult
                      onClick={() => selectAccount(acct)}
                      key={index}
                    >
                      {acct.acct_nm} - {acct._acct_id}
                    </SearchResult>
                  );
                })}
              </SearchInput>
              {/* Opportunity Dropdown */}
              <Combobox
                id="searchOpp"
                className="fieldMargin"
                disabled={disableOpp}
                label="Salesforce Opportunity Name *"
                onChange={(value) => selectOpp(value)}
                value={oppName} // set the current value
              >
                {oppsList.map((opp: any, index) => (
                  <ComboboxOption
                    key={index}
                    value={JSON.stringify({
                      name: opp.opp_nm,
                      sfdc_id: opp._id,
                    })}
                    displayName={opp.opp_nm}
                  />
                ))}
              </Combobox>

              {/* Three Whys */}
              <TextInput
                className="fieldMargin"
                baseFontSize={13}
                label="Why Now *"
                value={whyNow}
                onChange={(event) => setWhyNow(event.target.value)}
                optional={false}
              />
              <TextInput
                className="fieldMargin"
                baseFontSize={13}
                label="Why Anything *"
                value={whyAnything}
                onChange={(event) => setWhyAnything(event.target.value)}
                optional={false}
              />
              <TextInput
                className="fieldMargin"
                baseFontSize={13}
                label="Why MongoDB *"
                value={whyMongoDB}
                onChange={(event) => setWhyMongoDB(event.target.value)}
                optional={false}
              />
              {/* Lead Time Badge */}
              <Badge variant="yellow">Lead Time: {leadTime}+ days</Badge>
              {/* Meeting Date... and Time(?) */}
              <Row xs={12} md={12} lg={12}>
                {dateOptionalHidden ? null : (
                  <Checkbox
                    className="my-checkbox fieldMargin"
                    onChange={() => {
                      if (isOptionalDateChecked) {
                        setOptionalDateChecked(false);
                      } else {
                        setOptionalDateChecked(true);
                      }
                    }}
                    label="I do not yet know the date and time of the meeting"
                    checked={isOptionalDateChecked}
                    bold={false}
                  />
                )}
                <Col>
                  {isOptionalDateChecked ? null : (
                    <>
                      <Label className="fieldMargin" htmlFor="datePicker">
                        Prep Date
                      </Label>
                      <DatePicker
                        // minDate={addDays(new Date(), leadTime)}
                        minDate={addDays(new Date(), prepLeadTime)}
                        className="datePicker"
                        id="datePicker"
                        selected={prepDate}
                        onChange={(date: any) => setPrepDate(date)}
                        dateFormat="MMMM d, yyyy"
                        filterDate={isWeekday}
                      />
                    </>
                  )}
                </Col>
                <Col>
                  {isOptionalDateChecked ? null : (
                    <>
                      <Label className="fieldMargin" htmlFor="datePicker">
                        Meeting Date
                      </Label>
                      <DatePicker
                        // minDate={addDays(new Date(), leadTime)}
                        minDate={addDays(new Date(), leadTime)}
                        className="datePicker"
                        id="datePicker"
                        selected={meetingDate}
                        onChange={(date: any) => setMeetingDate(date)}
                        dateFormat="MMMM d, yyyy"
                        filterDate={isWeekday}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row xs={12} md={12} lg={12}>
                {/* <Combobox
                  label="CC"
                  className="fieldMargin"
                  placeholder="Search"
                  onFilter={(event) => searchEmail(event)}
                  onChange={(e) => handleExtraEmailField(e)}
                  multiselect={true}
                  disabled={true}
                >
                  {emailList.map((email: any, index) => {
                    return <ComboboxOption key={index} value={email} />;
                  })}
                </Combobox> */}
                <Combobox
                  label="Send Request to..."
                  className="fieldMargin"
                  placeholder="Search"
                  // onFilter={(event) => searchEmail(event)}
                  // onChange={(e) => setSendToEmailList(e)}
                  onChange={(e) => setEmailTo(e)}
                  multiselect={true}
                >
                  {emailToOptions.map((person: any, index) => {
                    return (
                      <ComboboxOption
                        key={index}
                        value={person.email}
                        displayName={` ${person.name} ${
                          person.region ? "- " + person.region : ""
                        }`}
                      />
                    );
                  })}
                </Combobox>
              </Row>
            </div>

            {/* Submit Button */}
            <Button
              className="input-submit-button"
              darkMode={true}
              disabled={false}
              onClick={() => setOpenInfoModal((curr) => !curr)}
            >
              Save and Submit Request
            </Button>
            <Modal open={openInfoModal} setOpen={setOpenInfoModal}>
              <div className="modal-content">
                <h2>Save Inputs</h2>
                <p>
                  Are you sure you want to save the inputs and submit request?
                </p>
                <pre>{JSON.stringify(form, null, 2)}</pre>
              </div>
              <Button
                className="input-submit-button"
                darkMode={true}
                disabled={false}
                onClick={() => {
                  setOpenInfoModal((curr) => !curr);
                  handleSubmit();
                }}
              >
                Save and Submit
              </Button>
              <Button
                className="input-submit-button"
                darkMode={true}
                disabled={false}
                onClick={() => {
                  setOpenInfoModal((curr) => !curr);
                }}
              >
                Cancel
              </Button>
            </Modal>
            <br />
            <br />
            <Callout variant="note" title="Reminder">
              Accts/Opps/WLs update every 24 hrs. If you’ve created an entry
              today, it won’t appear until tomorrow morning.
            </Callout>
          </Col>
          <Col></Col>
        </Row>
      </div>
    </Layout>
  );
};
