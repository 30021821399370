import { Col, Row } from "react-bootstrap";
// Components - Leafy
import { H1, Body } from "@leafygreen-ui/typography";
// Components - custom/internal

interface Props {
  title: string;
  breadcrumbText: string;
}

export const Hero = ({ title, breadcrumbText }: Props) => {
  return (
    <>
      <Row className="form-hero-image">
        <Col>
          <H1 className="form-hero-text">{title}</H1>
        </Col>
      </Row>
      <Row className="navigationRow">
        <Col></Col>
        <Col xs={12} md={10} lg={10}>
          <Body className="navigationStyle">
            <a className="navigationRefStyle" href="/">
              Home
            </a>
            <span> &#62; </span> {breadcrumbText}
          </Body>
        </Col>
        <Col></Col>
      </Row>
    </>
  );
};
