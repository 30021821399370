import Badge from "@leafygreen-ui/badge";
import ExpandableCard from "@leafygreen-ui/expandable-card";
// import Icon from "@leafygreen-ui/icon";

const InstructionsUat = ({ props }: any) => {
  // UAT Variables
  // const uatNumShards = Math.ceil(dataSize / 2 / 2000);
  // const uatCompressedDataSize = Math.ceil(
  //   ((dataSize / 2) * 0.4) / uatNumShards
  // );
  // const uatNumNodes = isMultiRegion ? 5 : 3;
  // const uatRamGbPerShard = Math.ceil((0.1 * dataSize) / 2 / uatNumShards);
  // const uatStorageSizePerShard = uatCompressedDataSize / uatNumShards;

  const {
    cloudProvider,
    compressedDataSize,
    numShards,
    numNodes,
    storageSizePerShard,
    ramGbPerShard,
    monthInUat,
  } = props;

  let steps = [];

  // Replication Factor <Multi-region number>.
  // If this answer is ‘3’, ignore.
  // If this answer is 5, create a new cluster with the same config as above with a second region and TWO nodes

  // MULTI-REGION
  {
    numNodes === 5
      ? steps.push(
          <>
            Enable <b>Multi-cloud, multi-region & Workload isolation</b>.<br />
            Make sure the region in the Region drop down is a common region for
            your territory.
            <br />
            Make sure Count is 3
            {/* Duplicate cluster or create another cluster with the same config as
          above with a second region and{" "}
          <Badge variant="blue" className="my-badge">
            2 nodes
          </Badge>{" "}
          in{" "}
          <Badge variant="lightgray" className="my-badge">
            Replication Factor
          </Badge> */}
          </>,
          <>
            Click
            <Badge variant="lightgray" className="my-badge">
              +Add Electable Node
            </Badge>
            <br />
            In the newly created line, choose the same Cloud provider as the
            first line. A second region that's common for your territory.
            <br />
            Make sure the Count is 2
          </>
        )
      : cloudProvider
      ? steps.push(
          <>
            {/* Enter the Cloud Provider */}
            Enter{" "}
            <Badge variant="blue" className="my-badge">
              {cloudProvider || "-"}
            </Badge>{" "}
            into{" "}
            <Badge variant="lightgray" className="my-badge">
              PROVIDER
            </Badge>
            <br />
          </>
        )
      : steps.push(
          <>
            Choose a{" "}
            <Badge variant="red" className="my-badge">
              Cloud Provider
            </Badge>{" "}
            above
            <br />
          </>
        );
  }

  ramGbPerShard
    ? steps.push(
        <>
          {/* <Icon glyph="ArrowRight" fill="#000000" />
      <Badge variant="lightgray" className="my-badge">
        MANAGED CLUSTER SIZE
      </Badge>
      <br /> */}
          Enter a cluster size where the{" "}
          <Badge variant="lightgray" className="my-badge">
            RAM
          </Badge>{" "}
          is{" "}
          <Badge variant="blue" className="my-badge">
            &ge; {Math.ceil(ramGbPerShard) + " GB RAM" || " "}
          </Badge>
        </>
      )
    : steps.push(
        <>
          Input{" "}
          <Badge variant="red" className="my-badge">
            Data size
          </Badge>{" "}
          above
          <br />
        </>
      );

  storageSizePerShard
    ? steps.push(
        <>
          Enter a{" "}
          <Badge variant="lightgray" className="my-badge">
            Disk Size (GB)
          </Badge>{" "}
          that is{" "}
          <Badge variant="blue" className="my-badge">
            &gt; {storageSizePerShard} GB
          </Badge>{" "}
          (if the included disk size isn't already big enough)
        </>
      )
    : steps.push(
        <>
          Input{" "}
          <Badge variant="red" className="my-badge">
            Data size
          </Badge>{" "}
          above
          <br />
        </>
      );

  // SHARDING - Enter <shard number> into Number of Shards dropdown.
  numShards > 1 &&
    steps.push(
      <>
        Enter{" "}
        <Badge variant="blue" className="my-badge">
          {numShards}
        </Badge>{" "}
        into{" "}
        <Badge variant="lightgray" className="my-badge">
          Number of Shards
        </Badge>
      </>
    );

  monthInUat
    ? steps.push(
        <>
          Enter{" "}
          <Badge variant="blue" className="my-badge">
            {monthInUat}
          </Badge>{" "}
          into{" "}
          <Badge variant="lightgray" className="my-badge">
            First Active Month
          </Badge>
        </>
      )
    : steps.push(
        <>
          Input{" "}
          <Badge variant="red" className="my-badge">
            Month Active in UAT
          </Badge>{" "}
          above
          <br />
        </>
      );
  steps.push(
    <>Use Subscription Type Enterprise to determine Total Cost w/ Discounts</>
  );

  return (
    <ExpandableCard
      title="UAT"
      description={
        <>
          Click{" "}
          <Badge variant="lightgray" className="my-badge">
            + Add New Cluster
          </Badge>{" "}
          in Pricing Calc.
          <br />
          Expand this card and follow the steps below.
        </>
      }
      flagText="User Acceptance Testing cluster or other lower environment"
      darkMode={false}
    >
      {steps.map((step, idx) => (
        <div key={idx}>
          <b>{idx + 1}</b>: {step}
        </div>
      ))}
    </ExpandableCard>
  );
};

export default InstructionsUat;
