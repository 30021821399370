import React, { useEffect, useState } from "react";
import * as Realm from "realm-web";
import { useOktaAuth } from "@okta/okta-react";
import { app } from "../../realmApp/realmApp";
// import { useNavigate } from "react-router-dom";
import "./earrCalculator.css";
import Layout from "../../components/Layout/Layout";
import { Col, Row } from "react-bootstrap";
// Components - Leafy
import { H1, Body, Link } from "@leafygreen-ui/typography";
import { Combobox, ComboboxOption } from "@leafygreen-ui/combobox";
import TextInput from "@leafygreen-ui/text-input";
import Toggle from "@leafygreen-ui/toggle";
import { RadioBox, RadioBoxGroup } from "@leafygreen-ui/radio-box-group";
import Button from "@leafygreen-ui/button";
import Modal from "@leafygreen-ui/modal";
import Callout from "@leafygreen-ui/callout";
import { SearchInput, SearchResult } from "@leafygreen-ui/search-input";
import { Tabs, Tab } from "@leafygreen-ui/tabs";
import ExpandableCard from "@leafygreen-ui/expandable-card";
import Badge from "@leafygreen-ui/badge";
// import ConfirmationModal from "@leafygreen-ui/confirmation-modal";
// Components - custom/internal
import InstructionsProd from "./components/InstructionCards/Prod";
import InstructionsUat from "./components/InstructionCards/UAT";
import InstructionsDev from "./components/InstructionCards/Dev";
import InputValidation from "./components/Modal/InputValidation";
// import TestCard from "./components/InstructionCards/_testCard";
//
import {
  // useTestCalcs,
  useDevCalcs,
  useProdCalcs,
  useUatCalcs,
} from "./lib/hooks/useCalcs";

interface Props {
  isProtected: boolean;
}

export const EarrCalculator = ({ isProtected }: Props) => {
  // const navigate = useNavigate();
  const [submissionName, setSubmissionName] = useState("");
  const [cloudProviderValue, setCloudProviderValue] = useState("");
  const [dataSizeInputValue, setDataSizeInputValue] = useState("");
  const [monthInProduction, setMonthInProduction] = useState("");
  const [monthInUat, setMonthInUat] = useState("");
  const [isMultiRegion, setIsMultiRegion] = useState(false);
  // Modal state
  const [openInfoModal, setOpenInfoModal] = useState(false);
  // const [openActionModal, setOpenActionModal] = useState(false);
  // Get the user from the context
  const [user, setUser] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  const [savedEstimates, setSavedEstimates] = useState([]);
  // This is the user used to invoke the functions
  const [appUser, setAppUser] = useState(null);
  // Account and Opp Search
  const [isAccountSelected, setIsAccountSelected] = useState(false);
  const [accountInfo, setAccountInfo] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [selectedOpp, setSelectedOpp] = useState("");
  const [disableOpp, setDisableOpp] = useState(true);
  const [oppsList, setOppsList] = useState([]);
  const [accountId, setAccountId] = useState("");
  const [opportunityFieldLabel, setOpportunityFieldLabel] = useState<string>(
    "Salesforce Opportunity Name *"
  );

  // Tabs state
  const [selected, setSelected] = useState(0);

  const formInputs = {
    cloudProvider: cloudProviderValue,
    dataSize: dataSizeInputValue,
    monthInProduction: monthInProduction,
    monthInUat: monthInUat,
    isMultiRegion: isMultiRegion,
  };

  const prodOutputObject = useProdCalcs(formInputs);
  const uatOutputObject = useUatCalcs(formInputs);
  const devOutputObject = useDevCalcs(formInputs);

  const dbSubmitObject = {
    user: appUser?._profile.data.email,
    accountName: accountInfo,
    oppName: selectedOpp,
    submissionName,
    input: formInputs,
    prod: prodOutputObject,
    uat: uatOutputObject,
    dev: devOutputObject,
  };

  const arrayRange = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
    );

  const cloudProviderOptions = ["AWS", "GCP", "Azure"];
  const monthInProductionOptions = arrayRange(1, 12, 1);
  const monthInUatOptions = arrayRange(1, 12, 1);

  useEffect(() => {
    document.title = "STEM: EARR Calc";
  }, []);

  // App Services/Realm functions
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else {
      oktaAuth.getUser().then((info) => {
        setUser(info);
        loginApiKey();
      });
    }
  }, [authState, oktaAuth]); // Update if authState changes

  const loginApiKey = () => {
    // Getting the JWT token from Okta authState and passing as an paran to Realm
    const credentials = Realm.Credentials.jwt(authState.idToken?.idToken);

    app.logIn(credentials).then((appUser) => {
      setAppUser(appUser);
    });
  };

  const searchAccount = async (event: any) => {
    if (isAccountSelected) {
      // Reset the flag once we've ignored one change.
      setIsAccountSelected(false);
      return;
    }
    const searchQuery = event.target.value;
    if (user === undefined) return;
    setAccountInfo(searchQuery);
    if (searchQuery.length < 3) {
      setAccountList([]);
      return;
    }
    let search = { searchTerm: searchQuery };
    const response = await appUser.functions.search_account(search);
    // console.log("searchAccount response", response);
    setAccountList(response.result);
    // setAccountList(["hello", "world", "this", "is", "a", "test"]);
    //clean the opp field
    setSelectedOpp("");
  };

  const selectAccount = async (acct: any) => {
    setIsAccountSelected(true);
    let search = { _acct_id: acct._acct_id };
    const response = await appUser.functions.search_opp(search);
    setOppsList(response.result);
    setDisableOpp(false);
    setAccountInfo(acct.acct_nm);
    setAccountId(acct._acct_id);
  };

  const save_calcs = async () => {
    if (user === undefined) return;

    // const submitObject = dbSubmitObject;

    // Call the Realm Function "create_zendesk_ticket"
    const result = await appUser.functions.submit_earrCalcs(dbSubmitObject);

    /*  Testing Only -- so that we view & check the newly created ticket
          Future: Redirect to https://help-sales.mongodb.com/hc/en-us/requests/{TICKETID}

      */
    if (result.status === 200) {
      alert("Success");
      // setProgressToastOpen(false);
      // setSuccessToastOpen(true); // set state to true on success
      // setTicketID(
      //   `https://help-sales.mongodb.com/hc/en-us/requests/${result.ticketId}`
      // );
    } else {
      alert("Error");
      // setProgressToastOpen(false);
      // setWarningToastOpen(true);
      // const errorBody = result.zendeskResponse.body;
      // const jsonObject = JSON.parse(errorBody);
      // setTicketWarning(`Error - ${jsonObject.details.base[0].description}`);
    }
    //}
  };

  const getSavedEstimates = async () => {
    if (user === undefined) return;

    const response = await appUser.functions.getEstimates(
      appUser._profile.data
    );

    setSavedEstimates(response.result);
  };

  console.log(savedEstimates);

  return (
    <Layout>
      <main>
        <Row className="form-hero-image">
          <Col>
            <H1 className="form-hero-text">EARR Calculator</H1>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> EARR Calculator
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            {/* <pre>{JSON.stringify(appUser._profile.data.email)}</pre> */}
            <Body as="h1">
              Logged in as:{" "}
              {appUser ? appUser._profile.data.email : "Loading..."}
            </Body>
          </Col>
          <Col></Col>
        </Row>
        <Row className="g-2 mt-2">
          <Col></Col>
          <Col xs={12} md={6} lg={6}>
            <Tabs
              setSelected={setSelected}
              selected={selected}
              aria-labelledby="tabName"
            >
              <Tab name="New Estimate">
                <TextInput
                  label="Estimate Name"
                  description="Tag this estimation for future reference"
                  placeholder="Initials-Account-Opp"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSubmissionName(e.target.value);
                  }}
                  value={submissionName}
                />
                <Body as="h1">
                  <b>Account</b>
                </Body>
                <SearchInput
                  id="accountName"
                  className="fieldMargin"
                  value={accountInfo}
                  onChange={(event) => searchAccount(event)}
                  aria-label="Account Name"
                >
                  {accountList.map((acct: any, index) => {
                    return (
                      <SearchResult
                        onClick={() => selectAccount(acct)}
                        key={index}
                      >
                        {acct.acct_nm} - {acct._acct_id}
                      </SearchResult>
                    );
                  })}
                </SearchInput>
                <Combobox
                  id="searchOpp"
                  className="fieldMargin"
                  disabled={disableOpp}
                  label={opportunityFieldLabel}
                  onChange={(value) => setSelectedOpp(value)}
                  value={selectedOpp} // set the current value
                >
                  {oppsList.map((opp: any, index) => (
                    <ComboboxOption
                      key={index}
                      value={opp.opp_nm}
                      displayName={opp.opp_nm}
                    />
                  ))}
                </Combobox>
                <Body as="h1">
                  <b>Choose Cloud Provider</b>
                </Body>
                <RadioBoxGroup
                  className="radio-box-group-style"
                  size="full"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setCloudProviderValue(e.target.value);
                  }}
                >
                  {cloudProviderOptions.map((item, idx) => (
                    <RadioBox key={idx} value={item}>
                      {item}
                    </RadioBox>
                  ))}
                </RadioBoxGroup>
                <TextInput
                  label="Data Size (uncompressed)"
                  description="How many gigs of data will you have in Production?"
                  placeholder=""
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setDataSizeInputValue(e.target.value);
                  }}
                  value={dataSizeInputValue}
                />
                <Combobox
                  label="How many months from now will this be active in Production?"
                  description=""
                  placeholder="1-12"
                  onChange={(value: any) => {
                    setMonthInProduction(value);
                  }}
                >
                  {monthInProductionOptions.map((item, idx) => (
                    <ComboboxOption key={idx} value={item.toString()} />
                  ))}
                </Combobox>
                <Combobox
                  label="How many months from now will this be active in UAT?"
                  description=""
                  placeholder="1-12"
                  onChange={(value: any) => {
                    setMonthInUat(value);
                  }}
                >
                  {monthInUatOptions.map((item, idx) => (
                    <ComboboxOption key={idx} value={item.toString()} />
                  ))}
                </Combobox>
                <br />
                <>
                  <label id="label" htmlFor="toggle">
                    <Body weight="medium">Multi-Region?</Body>
                  </label>
                  {"    "}
                  <Toggle
                    id="toggle"
                    aria-labelledby="label"
                    size="small"
                    checked={isMultiRegion}
                    onChange={(checked, event) => {
                      setIsMultiRegion(checked);
                    }}
                  />
                </>
                <div>
                  <Button
                    className="input-submit-button"
                    darkMode={true}
                    disabled={false}
                    onClick={() => setOpenInfoModal((curr) => !curr)}
                  >
                    Save Inputs
                  </Button>
                  <Modal open={openInfoModal} setOpen={setOpenInfoModal}>
                    <InputValidation />
                    INPUTS
                    <div>
                      Cloud Provider:{" "}
                      {cloudProviderValue.toString() || "Not entered"}
                    </div>
                    <div>
                      Uncompressed Data Size:{" "}
                      {dataSizeInputValue.toString() || "Not entered"}
                    </div>
                    <div>
                      Production month:{" "}
                      {monthInProduction.toString() || "Not entered"}
                    </div>
                    <div>
                      UAT month: {monthInUat.toString() || "Not entered"}
                    </div>
                    <div>
                      Multi-region: {isMultiRegion.toString() || "Not entered"}
                    </div>
                    <br />
                    <div>
                      <Body>Form Inputs Object</Body>
                      <pre>{JSON.stringify(formInputs, null, 2)}</pre>
                      <Body>
                        This is what will be submitted to database...
                        <br />
                        SEND TO SERVER:
                      </Body>
                      <pre>{JSON.stringify(dbSubmitObject, null, 2)}</pre>
                    </div>
                    <Button
                      className="input-submit-button"
                      darkMode={true}
                      disabled={false}
                      onClick={() => {
                        save_calcs();
                        setOpenInfoModal((curr) => !curr);
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      className="input-submit-button"
                      darkMode={true}
                      disabled={false}
                      onClick={() => {
                        setOpenInfoModal((curr) => !curr);
                      }}
                    >
                      Cancel
                    </Button>
                  </Modal>
                </div>
                <hr />
                <h3>Instructions</h3>
                <Body>
                  Click{" "}
                  <Link href="https://atlas-pricing.corp.mongodb.com/">
                    Atlas Pricing Calculator
                  </Link>
                </Body>
                <Body>
                  Click{" "}
                  <Badge variant="lightgray" className="my-badge">
                    + New Estimate
                  </Badge>{" "}
                  in Atlas Pricing Calculator
                </Body>
                <Body>
                  Follow steps in cards below for each environment (Prod, UAT,
                  Dev)
                </Body>
                <br />
                {/* <TestCard props={useTestCalcs({ ...formInputs })} /> */}
                {/* <hr /> */}
                <InstructionsProd props={prodOutputObject} />
                <InstructionsUat props={uatOutputObject} />
                <InstructionsDev props={devOutputObject} />
                <hr />
                <div>
                  {/* <Body>
                Reminder: Please note that this estimate is meant to provide a
                directionally accurate number. Please review with your SA for
                accuracy once completed.
              </Body> */}
                  <Callout variant="note" title="Reminder">
                    Please note that this estimate is meant to provide a
                    directionally accurate number. Please review with your SA
                    for accuracy once completed.
                  </Callout>
                </div>
              </Tab>
              <Tab name="Saved Estimates">
                <div>
                  {savedEstimates.length > 0 ? (
                    savedEstimates.map((item, i) => (
                      <ExpandableCard
                        key={i}
                        title={item.submissionName}
                        description={`Created: ${item.createdAt.toLocaleString()}`}
                        flagText={item.oppName}
                        darkMode={false}
                      >
                        <>
                          <div>
                            <b>Cloud Provider:</b> {item.input.cloudProvider}
                          </div>
                          <div>
                            <b>Raw Data Size:</b> {item.input.dataSize} GB
                          </div>
                          <div>
                            <b>Month in Production:</b>{" "}
                            {item.input.monthInProduction}
                          </div>
                          <div>
                            <b>Month in UAT:</b> {item.input.monthInUat}
                          </div>
                          <div>
                            <b>Regionality:</b>{" "}
                            {item.input.isMultiRegion
                              ? "Multi-Region"
                              : "Single-region"}
                          </div>
                        </>
                        <hr />
                        {JSON.stringify(item, null, 2)}
                        <>
                          <div></div>
                        </>
                      </ExpandableCard>
                    ))
                  ) : (
                    <Button
                      className="input-submit-button"
                      darkMode={true}
                      disabled={false}
                      onClick={() => {
                        getSavedEstimates();
                      }}
                    >
                      Load Saved Estimates
                    </Button>
                  )}
                </div>
                {/* <div>{JSON.stringify(savedEstimates)}</div> */}
              </Tab>
              {/* <Tab name="Tab Three">Tab Content Three</Tab> */}
            </Tabs>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </main>
    </Layout>
  );
};
