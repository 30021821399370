// Test calculations
// from input form to feed into Instructions components
export const useTestCalcs = ({
  cloudProvider,
  dataSize,
  monthInProduction,
  // monthInUat,
  isMultiRegion,
}: any) => {
  const compressedDataSize = dataSize * 0.4;
  const numShards = Math.ceil(dataSize / 2000);
  const numNodes = isMultiRegion ? 5 : 3;
  const storageSizePerShard = compressedDataSize / numShards;
  const ramGbPerShard = (0.1 * dataSize) / numShards;

  const testOutput = {
    cloudProvider,
    compressedDataSize,
    numShards,
    numNodes,
    storageSizePerShard,
    ramGbPerShard,
    monthInProduction,
  };

  return testOutput;
};

// Production Cluster calculations
// from input form to feed into Instructions components
export const useProdCalcs = ({
  cloudProvider,
  dataSize,
  monthInProduction,
  // monthInUat,
  isMultiRegion,
}: any) => {
  const numShards = Math.ceil(dataSize / 2000);
  const compressedDataSize = Math.ceil(dataSize * 0.4);
  const numNodes = isMultiRegion ? 5 : 3;
  const ramGbPerShard = Math.ceil((0.1 * dataSize) / numShards);
  const storageSizePerShard = Math.ceil(compressedDataSize / numShards);

  const prodOutput = {
    cloudProvider,
    compressedDataSize,
    numShards,
    numNodes,
    storageSizePerShard,
    ramGbPerShard,
    monthInProduction: Number(monthInProduction),
  };

  return prodOutput;
};

// UAT Cluster calculations
// from input form to feed into Instructions components
export const useUatCalcs = ({
  cloudProvider,
  dataSize,
  // monthInProduction,
  monthInUat,
  isMultiRegion,
}: any) => {
  const numShards = Math.ceil(dataSize / 2000);
  const compressedDataSize = Math.ceil(dataSize * 0.5 * 0.4);
  const numNodes = isMultiRegion ? 5 : 3;
  const ramGbPerShard = (dataSize * 0.5 * 0.4 * 0.1) / numShards;
  const storageSizePerShard = Math.ceil(compressedDataSize / numShards);

  const uatOutput = {
    cloudProvider,
    compressedDataSize,
    numShards,
    numNodes,
    storageSizePerShard,
    ramGbPerShard,
    monthInUat: Number(monthInUat),
  };

  return uatOutput;
};

// DEV Cluster calculations
// static
export const useDevCalcs = ({ cloudProvider }: any) => {
  const compressedDataSize = 40;
  const numNodes = 3;
  const ramGbPerShard = 8;
  const monthInDev = 1;

  const devOutput = {
    cloudProvider,
    compressedDataSize,
    numNodes,
    ramGbPerShard,
    monthInDev,
    // isMultiRegion,
  };

  return devOutput;
};
