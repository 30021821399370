import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

// components - LeafyGreen
import Layout from "../../components/Layout/Layout";
import { H1, H3, Body } from "@leafygreen-ui/typography";
import Button from "@leafygreen-ui/button";
import {
  Combobox,
  ComboboxOption,
  ComboboxGroup,
} from "@leafygreen-ui/combobox";
// import { Pipeline, Stage } from "@leafygreen-ui/pipeline";
import Modal from "@leafygreen-ui/modal";
import { Tabs, Tab } from "@leafygreen-ui/tabs";

// styles
import "./TigerProcess.css";
//
import {
  // keyActivities,
  // assetList,
  // otherResources,
  fastStages,
} from "./lib/tiger/fast/stages";
import { ClassicProcessComponent } from "./components/Classic/ClassicProcess";
import { SprintProcessComponent } from "./components/Sprint/SprintProcess";
import { FastProcessComponent } from "./components/Fast/FastProcess";

interface Props {
  isProtected: boolean;
}

// const testStages = [
//   { title: "Discovery", stageValue: "discovery" },
//   { title: "NBM Gate", stageValue: "nbm" },
// ];

export const TigerProcessComponent = ({ isProtected }: Props) => {
  useEffect(() => {
    document.title = "STEM: Tiger Process";
  }, []);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(0);

  // const [selectedStage, setSelectedStage] = useState(null);

  // const [testStage, setTestStage] = useState(testStages[0]);

  // const closeModal = () => {
  //   setSelectedStage(null);
  // };

  return (
    <Layout>
      <div>
        <Row className="form-hero-image">
          <Col>
            <H1 className="hero-text">Tiger Route Assets</H1>
            <H3 className="hero-text-subtitle">
              Tiger Sales Process information and assets
            </H3>
          </Col>
        </Row>
        <Row className="navigationRow">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Body className="navigationStyle">
              <a className="navigationRefStyle" href="/">
                Home
              </a>
              <span> &#62; </span> Tiger Process
            </Body>
          </Col>
          <Col></Col>
        </Row>
        {/* <Row className="page-body-menu-select">
          <Col></Col>
          <Col xs={12} md={5} lg={5}>
            <Combobox
              label="Role"
              description="What is your role?"
              placeholder="Select role"
            >
              <ComboboxOption value="AE" />
              <ComboboxOption value="CSM" />
              <ComboboxOption value="EM" />
              <ComboboxOption value="SA" />
            </Combobox>
          </Col>
          <Col xs={12} md={5} lg={5}>
            <Combobox
              label="Route"
              description="Which route?"
              placeholder="Select route"
            >
              <ComboboxOption value="Classic" />
              <ComboboxOption value="Sprint" />
              <ComboboxOption value="Fast" />
            </Combobox>
          </Col>
          <Col></Col>
        </Row> */}

        <Row className="page-body-route-steppers">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <Tabs aria-label="Process tabs">
              <Tab name="Classic" default>
                <ClassicProcessComponent />
              </Tab>
              <Tab name="Sprint">
                <SprintProcessComponent />
              </Tab>
              <Tab name="Fast">
                <FastProcessComponent />
              </Tab>
              <Tab name="Not sure">
                <FastProcessComponent />
              </Tab>
            </Tabs>
            {/* <Card className="my-card">
              Classic
              <Pipeline size="xsmall" className="my-pipeline">
                <Stage className="stage">Discovery</Stage>
                <Stage>NBM Gate</Stage>
                <Stage>Scope</Stage>
                <Stage>Go/No-Go Gate</Stage>
                <Stage>Validation (BVA/POV/TDD/TFW)</Stage>
                <Stage>Echoback Gate</Stage>
                <Stage>Pre-Onboarding</Stage>
                <Stage>Pre-Onboarding Checklist Complete Gate</Stage>
                <Stage>Negotiate</Stage>
                <Stage>Handover</Stage>
              </Pipeline>
            </Card> 

            <Card className="my-card">
              Sprint
              <Pipeline size="xsmall" className="my-pipeline">
                <Stage>Discovery</Stage>
                <Stage>NBM Gate</Stage>
                <Stage>Validation (BVA/POV/TDD/TFW)</Stage>
                <Stage>Echoback Gate</Stage>
                <Stage>Pre-Onboarding</Stage>
                <Stage>Pre-Onboarding Checklist Complete Gate</Stage>
                <Stage>Negotiate</Stage>
                <Stage>Handover</Stage>
              </Pipeline>
            </Card>
            <Card className="my-card">
              Fast
              <Pipeline size="xsmall" className="my-pipeline">
                <Stage>Discovery</Stage>
                <Stage>NBM Gate</Stage>
                <Stage>Pre-Onboarding</Stage>
                <Stage>Pre-Onboarding Checklist Complete Gate</Stage>
                <Stage>Negotiate</Stage>
                <Stage>Handover</Stage>
              </Pipeline>
            </Card> */}
          </Col>
          <Col></Col>
        </Row>
        <hr />
        <Row className="page-body-modals">
          <Col></Col>
          <Col xs={12} md={10} lg={10}>
            <>
              <Button onClick={() => setOpen((curr) => !curr)}>
                TestModal: Tiger Fast - Discovery
              </Button>

              <Modal open={open} setOpen={setOpen}>
                Tiger Fast - Discovery
                <Tabs
                  setSelected={setSelected}
                  selected={selected}
                  aria-labelledby="tabs-label"
                >
                  <Tab name="Key Activities">
                    {fastStages.keyActivities.map((activity, i) => (
                      <div key={i}>
                        <h5>{activity.topic}</h5>
                        <ul>
                          {activity.items.map((item, j) => (
                            <li key={j}>{item}</li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </Tab>
                  <Tab name="Assets">
                    {fastStages.assetList.map((asset, i) => (
                      <div key={i}>
                        <h5>{asset.role}</h5>
                        <ul>
                          {asset.assets.map((item, j) => (
                            <li key={j}>
                              <a href={item.link}>{item.title}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </Tab>
                  <Tab name="Other Resources">
                    {fastStages.otherResources.map((resource, i) => (
                      <div key={i}>
                        <h5>{resource.role}</h5>
                        <ul>
                          {resource.assets.map((item, j) => (
                            <li key={j}>
                              <a href={item.link}>{item.title}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </Tab>
                </Tabs>
              </Modal>
              <hr />

              {/* <SegmentedControl
                name="segmented-stages"
                size={"default"}
                darkMode={false}
                followFocus={true}
                value={testStage.stageValue}
                onChange={(value: string) => {
                  console.log(value);
                  // setTestStage(value);
                }}
              > */}
              {/* {testStages.map((stage) => (
                  <>
                    <SegmentedControlOption value={stage.stageValue}>
                      {stage.title}
                    </SegmentedControlOption>
                  </>
                ))} */}

              {/* <SegmentedControlOption value="apple">
                  Apple
                </SegmentedControlOption>

                <SegmentedControlOption value="banana">
                  Banana
                </SegmentedControlOption>

                <SegmentedControlOption value="carrot">
                  Carrot
                </SegmentedControlOption>

                <SegmentedControlOption value="dragonfruit" disabled>
                  Dragonfruit
                </SegmentedControlOption> */}
              {/* </SegmentedControl> */}
              {/* <div>
                <div>Selected: {testStage}</div>
              </div> */}
            </>
            <div className="box">
              <div className="right-triangle"></div>
              <div className="left-triangle"></div>
              <div className="text-in-box">Hello</div>
            </div>

            <div className="arrow-right">hey</div>
            <div className="box">World</div>
          </Col>
          <Col></Col>
        </Row>

        <br />
        <br />
        <br />
        <br />
      </div>
    </Layout>
  );
};
