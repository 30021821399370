import { Col, Row } from "react-bootstrap";

const PreOnboarding = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>
            <b>SA verifies </b>all pre-onboarding activities have been completed
          </div>
          <br />
          <div>
            Remaining pre-onboarding scheduled and <b>executed by SA</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>

          <div>SA</div>
          <ul>
            <li>Pre-Onboarding Tab in Salesforce Opportunity</li>
          </ul>

          <div>EM</div>
          <ul>
            <li>
              <a href="https://mongodb.highspot.com/items/6230db49e3598d260d482345?lfrm=srp.3">
                PS Jumpstart
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>

          <div>SA</div>
          <ul>
            <li>
              <a href="https://mongodb.mindtickle.com/new/ui/learner#/1735358822424910429?series=1735295958826315655">
                Pre-Onboarding Process Improvements
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default PreOnboarding;
