import { Col, Row } from "react-bootstrap";

const NBMGate = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>Goal of NBM</div>
          <ul>
            <li>Qualify Champion</li>
            <li>Qualify importance of NWL</li>
            <li>Assess the MDB fit</li>
            <li>Agree on engagement model</li>
          </ul>
          <div>
            Use <b>Value Pyramid</b> and/or complemented by the{" "}
            <b>Command of the Message</b> summary to get credibility we
            understand their business/problem
          </div>
          <br />
          <div>
            Share <b>MongoDB point of view</b> to demonstrate how we can help
          </div>
          <br />
          <div>
            <b>Use Relevant Proof Points</b> to get credibility we have done
            this before
          </div>
          <br />
          <div>
            Agree on <b>engagement model</b>
          </div>
          <br />
          <div>How to Close NBM</div>
          <ul>
            <li>
              Agree on <b>Technical Feasibility</b> workshop date and
              participants/groups
            </li>
            <li>
              Send meeting recap email to confirm agreed upon next steps, date
              of the TFW and their sponsorship
            </li>
          </ul>

          <div>Attendance</div>
          <ul>
            <li>RD</li>
            <li>Rep</li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1RLZz5qLsVzq0CoFPLNLsDLj2jzzigw7esOQQ7PaaJBY/edit#slide=id.g20e5e478ad5_0_7666">
                NBM Template: Tiger Classic (AE)
              </a>
            </li>
          </ul>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
          <div>AE</div>
          <ul>
            <li>
              <a href="https://docs.google.com/presentation/d/1h-xYXoYvKcUdMK8LTJ9PZNnOfzgSMqvrKYJ6xiGGpCU/edit#slide=id.g8a5b4d2676_0_0">
                Value Pyramids Training (AE)
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default NBMGate;
