import Badge from "@leafygreen-ui/badge";
import ExpandableCard from "@leafygreen-ui/expandable-card";
import Icon from "@leafygreen-ui/icon";
// import { Body, Link } from "@leafygreen-ui/typography";

const InstructionsDev = ({ props }: any) => {
  const {
    cloudProvider,
    compressedDataSize,
    numNodes,
    ramGbPerShard,
    monthInDev,
    // isMultiRegion,
  } = props;

  const devInstructionSteps = [
    <>
      {/* Enter the Cloud Provider */}
      Enter{" "}
      <Badge variant="blue" className="my-badge">
        {cloudProvider || "-"}
      </Badge>{" "}
      into{" "}
      <Badge variant="lightgray" className="my-badge">
        PROVIDER
      </Badge>
    </>,
    <>
      Choose{" "}
      <Badge variant="blue" className="my-badge">
        M10
      </Badge>{" "}
      as{" "}
      <Badge variant="lightgray" className="my-badge">
        MANAGED CLUSTER SIZE
      </Badge>
    </>,
    <>Use Subscription Type Enterprise to determine Total Cost w/ Discounts</>,
    // <>
    //   {/* <Icon glyph="ArrowRight" fill="#000000" />
    // <Badge variant="lightgray" className="my-badge">
    //   MANAGED CLUSTER SIZE
    // </Badge>
    // <br /> */}
    //   Enter a cluster size where the{" "}
    //   <Badge variant="lightgray" className="my-badge">
    //     RAM
    //   </Badge>{" "}
    //   is{" "}
    //   <Badge variant="blue" className="my-badge">
    //     &ge; {Math.ceil(ramGbPerShard) + " GB RAM" || " "}
    //   </Badge>
    // </>,
    // <>
    //   Enter a{" "}
    //   <Badge variant="lightgray" className="my-badge">
    //     Disk Size (GB)
    //   </Badge>{" "}
    //   that is{" "}
    //   <Badge variant="blue" className="my-badge">
    //     &gt; {compressedDataSize} GB
    //   </Badge>{" "}
    //   (if the included disk size isn't already big enough)
    // </>,
    // <>
    //   Enter{" "}
    //   <Badge variant="blue" className="my-badge">
    //     {numNodes} nodes
    //   </Badge>{" "}
    //   into{" "}
    //   <Badge variant="lightgray" className="my-badge">
    //     Replication Factor
    //   </Badge>
    // </>,
    // <>
    //   Enter{" "}
    //   <Badge variant="blue" className="my-badge">
    //     {monthInDev}
    //   </Badge>{" "}
    //   into{" "}
    //   <Badge variant="lightgray" className="my-badge">
    //     First Active Month
    //   </Badge>
    // </>,
  ];

  return (
    <ExpandableCard
      title="Dev"
      description={
        <>
          Click{" "}
          <Badge variant="lightgray" className="my-badge">
            + Add New Cluster
          </Badge>{" "}
          in Pricing Calc.
          <br />
          Expand this card and follow the steps below.
        </>
      }
      flagText="Development cluster - M10 by default"
      darkMode={false}
    >
      {devInstructionSteps.map((step, idx) => (
        <div key={idx}>
          <b>{idx + 1}</b>: {step}
        </div>
      ))}
    </ExpandableCard>
  );
};

export default InstructionsDev;
