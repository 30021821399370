import { Col, Row } from "react-bootstrap";

const PreOnboardingGate = () => {
  return (
    <div>
      <Row>
        <Col lg={4}>
          <div>
            <b>Key Activities</b>
          </div>
          <div>
            <b>SA </b> confirms Pre-onboarding
          </div>
          <br />
          <div>
            <b>
              Pre-onboarding object in SFDC (formerly the Pre-Onboarding
              Checklist)
            </b>{" "}
            is completed
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Assets</b>
          </div>
        </Col>
        <Col lg={4}>
          <div>
            <b>Other Resources</b>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PreOnboardingGate;
